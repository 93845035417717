﻿.box-bai-viet-moi .box-posst-news-user {
    background-color: #e9ebee;
    padding: 10px;
    border-radius: 5px;
}

.box-bai-viet-moi .box-posst-news-user .view-action {
    margin-top: 10px;
}

.box-bai-viet-moi .box-posst-news-user p.color-gray.font-10.m-0 {
    color: #616770;
    font-size: 11px;
}

.box-bai-viet-moi .box-posst-news-user .box-bai-viet-cua-ban .box-bai-viet-header span.location-text {
    font-size: 13px;
    font-family: Arial;
}

.box-bai-viet-moi .box-posst-news-user .box-bai-viet-cua-ban .box-bai-viet-header span.text-checkin {
    font-size: 13px;
    font-family: Arial;
}

.box-bai-viet-moi .box-posst-news-user .box-bai-viet-cua-ban .box-bai-viet-header .date-baiviet {
    font-size: 12px;
    font-family: Arial;
}

.box-bai-viet-moi .box-posst-news-user .box-bai-viet-cua-ban .box-bai-viet-content {
    font-size: 14px;
}

.box-bai-viet-moi .box-posst-news-user .box-bai-viet-cua-ban .list-attachment-baiviet {
    font-size: 15px;
}

.box-bai-viet-moi .box-posst-news-user .box-bai-viet-cua-ban .content-share {
    border: 1px solid #b3b3b3;
    border-radius: 10px;
}

.box-posst-news-user .box-bai-viet-cua-ban {
    padding-bottom: 5px;
    border-bottom: none;
}


.box-posst-news-user .box-bai-viet-cua-ban .content-share .box-bai-viet-content {
    color: #616770;
}
/*.content-binh-luan p {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}*/
.list-attachment-baiviet .div-item-img {
    padding: 2px;
    border-radius: 5px;
}

.list-attachment-baiviet .div-viewmore-img {
    position: relative;
}

.list-attachment-baiviet .div-viewmore-img::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(99, 91, 91, 0.5);
}

.list-attachment-baiviet .div-viewmore-img .img-viewmore {
    position: absolute;
    top: 30%;
    left: 30%;
}

.list-attachment-baiviet .div-viewmore-img .img-viewmore span {
    font-size: 60px;
    color: white;
}


.list-attachment li.li_FileAttach {
    display: inline-block;
    padding: 5px;
    position: relative;
}

.list-attachment li.li_FileAttach img.imgage-item {
    width: 108px;
    height: 100px;
}

.list-attachment li.li_FileAttach a.act-delete-img {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(178, 31, 45, 0.7);
    padding: 0px 5px;
    border-radius: 50%;
    width: 21px;
    height: 23px;
}

//Binh luan
.box-bai-viet-moi .box-list-commnent-user {
    padding: 0px;
}

.box-bai-viet-moi .box-list-commnent-user .binh-luan-con {
    margin-left: 45px;
}

.box-list-commnent-user .box-list-content-comment {
    border-bottom: none !important;
}

.dropdown-action-custom button.btn-primary, .dropdown-action-custom button.btn-primary:hover, .dropdown-action-custom button.btn-primary:active, .dropdown-action-custom button.btn-primary:visited, .dropdown-action-custom button.btn-primary:focus {
    line-height: 0px;
    padding-bottom: 24px;
    font-size: 40px;
    background-color: unset !important;
    color: #6c757d;
    border: none;
}

.dropdown-action-custom button.btn-primary:hover, .dropdown-action-custom button.btn-primary:active, .dropdown-action-custom button.btn-primary:visited, .dropdown-action-custom button.btn-primary:focus {
    color: #1f2d3d;
}

.dropdown-action-custom .dropdown-menu {
    padding: 2px;
}

.dropdown-action-custom .dropdown-item {
    font-size: 14px;
    border-bottom: 1px solid #e9ebee;
}

.dropdown-action-custom button::before, .dropdown-action-custom button::after {
    display: none !important;
}

.dropdown-share button {
    padding-bottom: 0px !important;
}

.dropdown-status button {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    position: relative;
}

.dropdown-status {
    display: inline-block;
}

.dropdown-status button::after {
    display: inline-block !important;
    font-size: 20px;
    position: absolute;
    top: 5px;
}

.div-share {
    align-items: center;
}

.dropdown-share.btn-block .dropdown-toggle {
    width: 100%;
    height: 100%;
    padding: 0px;
}

.dropdown-share.btn-block .dropdown-toggle .share-sub-btn {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.view-like-modal .modal-content .modal-header {
    background-color: white;
    color: #6c757d;
    padding: 10px;
}

.view-like-modal .modal-content .modal-body {
    max-height: 550px !important;
}

.view-like-modal .modal-dialog .modal-header .h4 {
    font-size: 16px;
    font-weight: 400;
}

.view-like-modal .modal-content .modal-body div.ava-user {
    align-items: center;
    border-bottom: 1px solid #cdcdcd;
    padding: 5px;
}

.view-like-modal .modal-content .modal-body div.ava-user img {
    width: 45px;
    height: 45px;
}

.view-like-modal .modal-content .modal-body div.ava-user .common-friend {
    margin-bottom: 0px;
    font-size: 13px;
}

.modal-share .modal-content .modal-body {
    max-height: unset !important;
}

.control-edit-baidang .box-select-option {
    background-color: #044587;
    min-height: 40px;
    border: 1px solid #044587;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    padding-left: 10px;
    padding-right: 10px;
}

.control-edit-baidang .box-select-option .address select {
    background-color: #044587;
    color: #fff;
    border: none;
    border: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    padding: 5px;
}

.detail-baiviet-list-img .owl-nav {
    top: 45%;
    width: 100%;
}

.detail-baiviet-list-img .owl-nav .owl-prev {
    left: 10px;
}

.detail-baiviet-list-img .owl-nav .owl-next {
    right: 10px;
}

.detail-baiviet-list-img .owl-nav .owl-prev i, .wrap-main .owl-nav .owl-next i {
    font-size: 36px;
}

.detail-baiviet-list-img .owl-nav .owl-prev:hover, .wrap-main .owl-nav .owl-next:hover {
    background-color: #e9ecef !important;
}

.detail-baiviet-list-img .owl-nav .owl-prev:hover i, .detail-baiviet-list-img .owl-nav .owl-next:hover i {
    color: #007bff;
}

.detail-baiviet-list-img .owl-nav .owl-prev.disabled:hover, .wrap-main .owl-nav .owl-next.disabled:hover {
    background-color: unset !important;
}


.detail-baiviet-list-img .owl-carousel .owl-nav button.owl-prev, .detail-baiviet-list-img .owl-carousel .owl-nav button.owl-next, .detail-baiviet-list-img .owl-carousel button.owl-dot {
    width: 40px;
}

.text-pre-wrap {
    white-space: pre-wrap !important;
}

.loader-modal .modal-content {
    background: unset;
    top: 350px;
    box-shadow: unset;
    border: none;
}

.loader-modal .modal-dialog {
    width: 40px;
    border-radius: 50%;
}

.loader-modal .modal-content .loading-warp {
    background: unset;
}

.modal-open .modal {
    overflow: auto;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 300px;
    position: absolute;
    background-color: white;
    z-index: 10;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.box-quantam-baidang {
    background: #e9ebee;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 15px;
    padding-bottom: 5px;
}

.box-quantam-baidang .slick-slider {
    margin: 0px !important;
}

.box-quantam-baidang .slick-slider .slick-next {
    right: -15px;
}

.box-quantam-baidang .slick-slider .slick-prev {
    left: -15px;
}

.san-pham-goiy .item img {
    height: 130px;
}

.goiy-ketnoi .slick-slider .item, .goiy-ketnoi .slick-slider .item:focus {
    border: 1px solid #b3b3b3;
    height: 150px;
    border-radius: 10px;
    position: relative;
}

.goiy-ketnoi .slick-slider .item img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #b3b3b3;
}

.goiy-ketnoi .slick-slider .item .link-user {
    width: 100%;
    left: 0;
    color: black
}

.goiy-ketnoi .slick-slider .item .message {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 10px;
}

.box-quantam-baidang .slick-track {
    margin-left: 0px;
}

.goiy-sukien .slick-slider .item, .goiy-sukien .slick-slider .item:focus {
    border: 1px solid #b3b3b3;
    height: auto;
    border-radius: 0px;
    position: relative;
    background-color: white;
}

.goiy-sukien .slick-slider .item img {
    position: relative;
    width: 100%;
    max-height: 130px;
    border-radius: 0px;
    border: 1px solid #b3b3b3;
}

.goiy-sukien .slick-slider .item .item-title {
    font-size: 20px;
    color: #333333;
    font-weight: 600;
}

.goiy-sukien .slick-slider .item .item-title:hover {
    text-decoration: underline;
}

.goiy-tintuc .slick-slider .item, .goiy-tintuc .slick-slider .item:focus {
    border: none;
    height: auto;
    border-radius: 5px;
    position: relative;
    background-color: white;
}

.goiy-tintuc .slick-slider .item img {
    position: relative;
    width: 100%;
    height: 130px;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
}

.goiy-tintuc .slick-slider .item .item-title {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
}

.goiy-tintuc .slick-slider .item .item-title:hover {
    text-decoration: underline;
}

.btn-custom-sm {
    padding: 2px 0.5rem;
}

.box-action .btn-block + .btn-block {
    margin-top: 0px;
}

.box-action .btn-block {
    font-weight: 600;
}

.box-right-baiviet {
    background-color: #e9ebee;
    border-radius: 5px;
    margin-bottom: 15px;
}

.box-right-baiviet .btn-xem-them {
    border-top: 1px solid rgb(192, 192, 192)
}
.itemTimKiem{
    display:block !important;
}

.form-select.form-control .dropdown.radio-select .tag-list .tag-item .tag, .form-select.form-control .dropdown.simple-select .tag-list .tag-item .tag {
    display: flex;
}




//Phản ánh
.phan-anh-form {
    background: none !important;
}
.phan-anh-form .form-label {
    font-size: 14px !important;
    font-weight: 500 !important;
}
.phan-anh-form .header {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #044587;
}

.div-tags .cNrjqJ {
    background: none;
    box-shadow: none;
    width:100%;
    position:relative;
    font-size:12px;
    padding:0px;
}
.div-tags input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

.phan-anh-list .item-phan-anh {
    padding: 15px;
    font-size: 12px;
    font-family: 'Roboto-Regular';
    color: #333333;
    position: relative;
}
.col-left .phan-anh-list .item-phan-anh {
    min-height: 550px;
}
.phan-anh-list .item-phan-anh .title {
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    color: #333333;
}
.phan-anh-list .item-phan-anh .font-bold, .phan-anh-detail .font-bold {
    font-family: 'Roboto-Bold';
}
.phan-anh-list .item-phan-anh .box-action {
    width: 70%;
    position: absolute;
    bottom: 20px;
}
.phan-anh-list .item-phan-anh .box-action i {
    font-size: 18px;
    color: #999999;
}

.phan-anh-detail {
    font-size: 14px;
    font-family: 'Roboto-Regular';
    color: #333333;
}
.phan-anh-detail .title, .view-share-phan-anh .title {
    font-size: 30px;
    font-family: 'Roboto-Bold';
    color: #044587;
}
.phan-anh-detail .label {
    font-size: 14px !important;
    font-family: 'Roboto-Light';
    font-weight: normal !important;
}
.phan-anh-detail .price, .view-share-phan-anh .price {
    font-size: 30px;
    font-family: 'Roboto-Light';
    color: #A80713;
}
.phan-anh-detail .color-blue {
    color: #044587;
}
.phan-anh-detail .tags {
    font-size: 14px !important;
    font-family: 'Roboto-Light';
    font-weight: normal !important;
    color: #333333;
}
.phan-anh-detail .div-action {
    border-bottom: 1px solid #B3B3B3;
    border-top: 1px solid #B3B3B3;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 7.5px;
    width: 98%;
}
.phan-anh-detail hr {
    border-top: 1px solid #B3B3B3;
}
.phan-anh-detail .box-action {
    width: 40%;
}

.phan-anh-detail .box-action i {
    font-size: 18px;
    color: #999999;
}
.phan-anh-detail .item-phan-anh {
    font-size: 12px;
    font-family: 'Roboto-Regular';
    color: #333333;
}

.phan-anh-detail .item-phan-anh .title {
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    color: #333333;
    font-size: 12px;
}
.phan-anh-detail .binh-luan-form {
    font-size: 14px;
    font-family: 'Roboto-Regular';
}
.phan-anh-detail .binh-luan-form .form-control{
    font-size: 14px !important;
    font-family: 'Roboto-Regular';
}

.phan-anh-detail .box-list-commnent-user {
    width:100%;
}
.phan-anh-detail .image-pa {
    height: 410px;
}
.phan-anh-detail .image-gallery-left-nav .image-gallery-svg, .phan-anh-detail .image-gallery-right-nav .image-gallery-svg {    
    height: 90px;
}

.phan-anh-detail .image-gallery-thumbnails-wrapper.left, .phan-anh-detail .image-gallery-thumbnails-wrapper.right {
    height: 400px !important;
}
.phan-anh-detail .image-gallery-content.left .image-gallery-slide .image-gallery-image, .phan-anh-detail .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 400px;
}

.phan-anh-detail .fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh !important;
}

.phan-anh-detail .fullscreen .image-gallery-thumbnails-wrapper.left, .phan-anh-detail .fullscreen .image-gallery-thumbnails-wrapper.right {
    max-height: 100vh !important;
    min-height: 700px;
}
.phan-anh-detail .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border: 1px solid #007bff;
    height: 60px;
}


.phan-anh-detail .binh-luan-con {
    background: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
}
.phan-anh-detail .number-share {
    font-size: 14px;
    font-family: 'Roboto-Regular';
    color: #333333;
}
i.act-action{
    cursor:pointer;
}
.col-right.phan-anh-list .header {
    background-color: #E6E6E6;
    border-radius:10px;
    padding:10px;
    margin-bottom:25px;
}
.col-right.phan-anh-list .header .title {
    font-size: 18px;
    font-family: Roboto-Bold;
    color: #044587;
}
.col-right.phan-anh-list .header .icon-header {
    color: #A80713;
    font-size: 24px;
}
.col-right.phan-anh-list .item-phan-anh-left {
    padding-left:0px;
}
.col-right.phan-anh-list .item-phan-anh-right {
    padding-right: 0px;
}
.col-right.phan-anh-list .view-more {
    font-size: 12px;
    font-family: Roboto-Regular;
    color: #333333;
}



@media(max-width: 767.98px) {
    .wrap-main-danh-sach-bai-viet .col-left {
        padding-right: 10px !important;
    }
    .col-right.phan-anh-list .item-phan-anh-left {
        padding-left: 15px;
    }

    .col-right.phan-anh-list .item-phan-anh-right {
        padding-right: 15px;
    }

}