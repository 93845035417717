.MainLeft {
  overflow: auto;
  height: 100%;
}
.MainLeft::-webkit-scrollbar {
  width: 3px;
}

a.brand {
  // background-color: #e5e5e5;
  height: 55px;
  padding-top: 2px;
  box-sizing: border-box;
}

a.brand img {
  max-height: 90%;
}

.icon-add2 {
  position: relative;
}

.icon-add2::before {
  content: "+";
  font-size: 35px;
  line-height: 10px;
  position: absolute;
  right: 0;
}

.none-decoration {
  text-decoration: none;
}

.pull-right {
  float: right;
}

.gray-bg {
  background-color: var(--gray-bg);
}

.text-green {
  color: rgb(49, 181, 134);
}

.btn-warning {
  border-color: #ff7500f2 !important;
  background: #ff7500f2 !important;
  color: #fff !important;
}

.text-orange {
  color: #ff7500f2 !important;
}

.none-decoration:hover {
  text-decoration: none;
}

.dep-ic {
  border-radius: 100%;
  border: 1px solid #1db5e6;
}

.dep-ic:hover {
  border: 1px solid #3dbce273;
}

.not-outline-focus:focus {
  outline: none !important;
}

.btn-rounded {
  border: none;
  background-color: #e4e4e4;
  border-radius: 20px;
  padding: 4px 15px;
  font-weight: 500;
}

.btn-rounded:focus {
  outline: none !important;
}

.has-border-right {
  border-right: 1px solid #ccc;
}

.MainLeft {
  background-color: #fff;
  min-height: calc(100vh - 120px);
  border-right: 1px solid #c7c7c7;
}

.MainLeft__header {
  padding: 22px 21px 22px 35px;
  background-color: #fff;
  height: 65px;
  width: calc((100% - 80px) * 3 / 12 - 1px);
  position: fixed;
  top: 55px;
  border-right: 1px solid #eeeeee;
}

.MainLeft .mainleft__header2 {
  margin-top: 65px;
  padding: 22px 21px 22px 35px;
  box-shadow: 0 4px 3px -4px #bcbcbc;
}

.MainLeft .mainleft__header2 .item-phan-loai {
  position: relative;
  /* width: calc(100% / 3 - 10px); */
  margin-top: 10px;
  background-color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  color: gray;
  margin-right: 10px;
  border: 1px solid;
  cursor: pointer;
}

.MainLeft .mainleft__header2 .item-phan-loai .btn-close {
  position: absolute;
  padding: 0 !important;
  top: -6px;
  right: -3px;
  border: 1px solid;
  border-radius: 50%;
  line-height: 10px;
  display: none;
}

.MainLeft .mainleft__header2 .item-phan-loai.active {
  background-color: #4c9aff;
  color: #fff;
  border: 0px;
}

.MainLeft .mainleft__header2 .item-phan-loai:hover .btn-close {
  display: block;
  line-height: 5px;
}

.MainLeft .mainleft__header2 .item-phan-loai .btn-close svg {
  width: 10px !important;
  height: 10px !important;
}

@media only screen and (max-width: 600px) {
  .MainLeft {
    height: auto;
    border-bottom: 1px solid #ccc;
  }

  .MainLeft__header {
    width: 100%;
  }
}

.ListProjectGroup__header {
  padding: 10px 15px 10px 15px;
  background-color: #fff;
  height: 65px;
  width: calc((100% - 80px) * 3 / 12);
  position: fixed;
  top: 55px;
  border-right: 1px solid #c7c7c7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0px 5px #bcbcbc;
}

.ListProjectGroup .mainleft__header2 {
  margin-top: 65px;
  padding: 10px 15px 10px 15px;
  // box-shadow: 1px 1px 4px #bcbcbc;
}

.add-departmenet {
  padding: 9px;
  color: #3636d6;
  background-color: transparent;
  margin: 5px 10px;
  width: calc(100% - 20px);
}

.submit-modal {
  color: #05a205;
  border: none;
  background-color: transparent;
}

.department-icon.active {
  background-color: #e6f0ff !important;
}

// .department-icon:hover {
//   background-color: #f2f5fa !important;
// }

.department-icon svg {
  fill: transparent;
}

.department-icon:hover svg {
  fill: #000;
}

.itemUserBoxMessage {
  text-decoration: none !important;
  color: #000;
  font-size: 18px;
  position: relative;
}

.itemUserBoxMessage small {
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: white;
  background-color: red;
  border-radius: 8px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.content-boxChat {
  max-width: 80%;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 18px;
  max-height: 18px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 600px) {
  .ListProjectGroup__header {
    width: 100%;
  }

  .department-icon {
    padding: 10px 0 !important;
    display: flex;
    justify-content: center;
  }

  .itemUserBoxMessage .spanMoveItem {
    display: none;
  }
}

.input-modal {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  outline: 0 !important;
}

.input-modal:focus {
  box-shadow: none !important;
}

.items-ic {
  width: 25%;
  text-align: center;
}

.items-ic img {
  width: 90%;
  margin: auto;
}

.icon-block {
  border-bottom: 1px solid #f3e8e8;
  padding-bottom: 20px;
}

.MainRight {
  /* border-bottom: 1px solid #c7c7c7; */
  background-color: #fff;
  padding-top: 65px;
}

.MainRight__action {
  height: 65px;
  position: fixed;
  width: calc((100% - 80px) * 9 / 12 + 1px);
  background-color: #fff;
  top: 0;
  top: 55px;
  z-index: 999;
}

.MainRight__action .MainRight__action--colLeft {
  position: relative;
  padding-left: 15px;
}

.MainRight__action .MainRight__action--colLeft .dotActive::after {
/*  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  background-color: #62cb00;
  bottom: 0;
  left: 48px;
  border-radius: 50%;
  border: 2px solid #fff;*/
}

.MainRight .MainRight__action ul {
  height: 65px;
  margin-right: 15px;
}

.MainRight .MainRight__action ul li {
  padding: 0 8px;
}

.MainRight .MainRight__action li.nav-item > div > a {
  text-decoration: none;
  font-size: 11px;
  text-align: center;
  color: #3a3939;
}

.MainRight .MainRight__action li.nav-item > a {
  text-decoration: none;
  font-size: 11px;
  text-align: center;
  color: #3a3939;
}

.MainRight .MainRight__action li .fa {
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  .MainRight__action {
    width: 100%;
    box-shadow: 1px 1px 4px 1px #ccc;
  }

  .MainRight .MainRight__action ul {
    height: 80px;
  }
}

.RowProject__avatar {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.RowProject td:first-child {
  width: 90px;
}

.RowProject td.progress-col {
  width: 150px;
}

.tong-so-nguoi-tham-gia img {
  width: 20px;
  border-radius: 100%;
}

.tong-so-nguoi-tham-gia .user-n:not(:first-child) img {
  box-shadow: -4px -1px #f8f9fa;
}

.tong-so-nguoi-tham-gia .user-n {
  width: 20px;
  height: 20px;
  margin-left: -2px;
}

.tong-so-nguoi-tham-gia .add-user-plus {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  display: inline-block;
  border: 1px solid #b3aeae;
  border-radius: 100%;
  text-align: center;
}

.tong-so-nguoi-tham-gia .user-count {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #8fb8e4;
  text-align: center;
  box-shadow: -4px 0px #f8f9fa;
}

.chat-box-col-right {
  height: calc(100vh - 65px - 55px - 50px);
  background-color: #bcbcbc;
  overflow: auto;
}

.box-input-chat {
  height: 50px;
  form {
    width: 100%;
    display: flex;
  }
}

.box-input-chat textarea.textareaAutosize {
  height: 100% !important;
  font-size: 14px;
  resize: none;
  padding-right: 100px;
}

.box-input-chat textarea:focus {
  outline: none;
  border: none;
  box-shadow: none;
  color: black;
}

.dropdown-basic {
  font-weight: bold !important;
  font-size: 25px !important;
  padding: 0 !important;
  color: #fff !important;
  margin-left: 15px;
  /* display: none !important; */
  visibility: hidden;
}

.box-chart-user:hover .dropdown-basic,
.box-chart-guest:hover .dropdown-basic {
  /* display: block !important; */
  visibility: visible;
}

.dropdown-basic:hover {
  color: #0068ff !important;
}

.dropdown-basic:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.dropdown-basic::after {
  display: none !important;
}

.box-chart-guest {
  margin-left: auto;
}

.box-chart-guest img {
  order: 3;
}

.box-chart-guest .dropdown-basic {
  order: 1;
  margin-right: 10px;
}

.chat-box-col-right .box-chart-user,
.box-chart-guest {
  margin-top: 20px;
  max-width: 50%;
  /* background-color: #DAE9FF; */
}

.chat-box-col-right .box-chart-user .boxMessage {
  padding: 15px;
  background-color: #fff;
  margin-left: 5px;
  border-radius: 5px;
}

.chat-box-col-right .box-chart-guest .boxMessage {
  padding: 15px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #dae9ff;
  order: 2;
}

@media only screen and (max-width: 600px) {
  .chat-box-col-right .box-chart-user,
  .box-chart-guest {
    margin-top: 20px;
    max-width: 80%;
    /* background-color: #DAE9FF; */
  }
}

.LeftCol {
  height: 100vh;
  position: fixed;
  width: 80px;
  background: linear-gradient(45deg, #33505e 0%, #4caf50 100%);
}

.LeftCol .LeftColItem {
  flex-direction: column;
  width: 80px;
  padding-bottom: 10px;
  flex: 1 1;
}

.LeftCol .left-icon {
  height: calc(100% - 100px);
}

.LeftCol .LeftColItem .Items {
  padding-top: 10px;
  padding-bottom: 10px;
}

.LeftCol .LeftColItem .Items:hover {
  background-color: #0000001f;
}

.LeftCol .LeftColItem .Items.active {
  background-color: #0000001f;
}

.LeftCol .LeftColItem .Items:hover a {
  text-decoration: none;
}

.LeftCol .LeftNavLink {
  /* flex: 1; */
  flex: 1 1;
  display: block;
  color: #fff;
}

.LeftCol .HomePage .Item {
  color: #fff;
}

.LeftCol .Project {
  flex: 1 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.LeftCol .Project .Item {
  color: #fff;
}

.TopNav {
  margin-left: 80px;
  background-color: #fff;
  height: 45px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: calc(100% - 80px);
  height: 55px;
  z-index: 1000;
}

.TopNav--Information {
  flex-direction: column;
}

.TopNav--Information-event {
  padding: 0px 10px;
  background-color: #ff9800;
  border-radius: 15px;
  color: #fff;
}

.TopNav--Items {
  flex-direction: row;
}

.TopNav--Right {
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .TopNav {
    margin-left: 0px;
    background-color: #fff;
    /* height: 45px; */
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    width: calc(100% - 0px);
    z-index: 1000;
  }
}

.TopNav .search input {
  border-radius: 20px;
  padding-left: 30px;
  position: relative;
  height: 35px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 9px;
  width: 300px;
}

.MainContent {
  margin-left: 80px;
  /* background: linear-gradient(0deg, #eeeeee 0%, #dbdbdb 100%); */
  padding-top: 55px;
}

.box-form-search {
  margin-top: 65px;
  padding: 20px 15px 5px 15px;
  input {
    border-radius: 20px;
  }
}
.modal-body-ketnoi .ava-user{
    padding:5px 0px;
}
li.department-icon.not-outline-focus.d-flex.align-items-center:hover {
    background-color: #e4e4e482;
}
.item-hoi-thoai-selected {
    background-color: #e4e4e482;
}
@media only screen and (max-width: 600px) {
    .MainContent {
        margin-left: 0px;
        /* background: linear-gradient(0deg, #eeeeee 0%, #dbdbdb 100%); */
        padding-top: 55px;
    }

    .TopNav--Items {
        padding: 5px;

        .search {
            margin-right: auto !important;
            margin: initial !important;
        }

        .box-user-right {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }
}
.TableProject{
    position:relative;
}
.search-mess {
    margin: 0;
    padding: 5px;
    background-color: #ccc;
    position: absolute;
    width:100%;
}
