@media(max-width: 767.98px) {
    .mt-mb-2{
        margin-top: 10px
    }
    .text-mb-left{
        text-align: left !important
    }
    .wrap-main {
        .itemSlideDoanhNghiepTichCuc {
            .logoDoanhNghiep {
                min-height: 150px !important;
                max-height: 150px !important;
                overflow: hidden;

                img {
                    width: 100% !important;
                    height: 150px !important
                }
            }
        }
        //css page su kien
        .box-ChiTietSuKien{
            
            .action-sukien {
                .box-notication {
                    flex-wrap: wrap;
                    .col-left, .col-right {
                        width: 100% !important;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px
                    }
                }
            }
        }

        .limited-line-mb-3 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        /*        nav menu mobile*/
        .box-navbar-menu-child {
            z-index: 9998 !important;
        }

        .logo-home {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }

        .box-navbar-menu {
            position: fixed !important;
            width: 80%;
            background: -webkit-linear-gradient(bottom, #02326f, #044379);
            z-index: 9999 !important;



            .form-search {
                input {
                    background-color: transparent;
                    color: #fff;
                    margin: 0 15px
                }

                .icon-search_box {
                    right: 20px;
                    color: #fff
                }
            }

            ul {
                li {
                    margin-top: 10px;
                    padding-right: 0px !important;

                    a {
                        color: #fff !important;

                        &.active {
                            background-color: #bcbcbc82 !important;
                        }
                    }

                    .menu-child {
                        width: 100% !important;
                        left: 0% !important;
                        background-color: #bcbcbc3d !important;
                        padding-left: 20px;

                        &.active {
                            display: block;
                            top: 37px;
                            position: initial;
                        }

                        ul {
                            &::after {
                                display: none;
                            }
                        }

                        &::after {
                            display: none;
                        }
                    }

                    &::after {
                        display: none;
                        height: 80%;
                        background-color: #fff;
                    }
                }
            }
        }
        /*      end  nav menu mobile*/
        .wrap-main-CTSP {
            .box-images-product {
                img {
                    width: 100%
                }
            }
        }

        .navbar-expand .navbar-nav {
            flex-direction: row !important;
        }

        .box_description-item {
            flex-wrap: wrap;

            .col-left, .col-right {
                width: 100%
            }

            .col-left {
                p {
                    font-size: 20px
                }
            }

            .col-right {
                margin-top: 5px
            }
        }

        .box-mo-ta-sp {
            flex-wrap: wrap;

            .col-left, .col-right {
                width: 100%
            }

            .col-right {
                margin-top: 15px;
                justify-content: center;
                align-items: center;
                display: flex
            }
        }

        .font-30 {
            font-size: 26px;
        }

        .item-SanPhamBaoCao {
            .main-col-item {
                margin-bottom: 15px !important;

                a {
                    img {
                        min-height: 300px;
                        max-height: 300px
                    }
                }
            }

            img {
                min-height: 150px;
                max-height: 150px
            }
        }

        .nav-menu {
            background: -webkit-linear-gradient(bottom, #02326f, #044379);
            position: relative;

            .navbar-toggler {
                background-color: rgba(4, 69, 135, 1);
            }

            ul.navbar-nav {
                flex-direction: row !important;

                li {
                    margin-right: auto;
                    width: 100%;
                }
            }

            .navbar-menu-mobile {
                position: fixed;
                min-height: 100vh;
                width: 80%;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                z-index: 999;
                // display: none !important;
                background: -webkit-linear-gradient(bottom, #02326f, #044379);

                .form-search {
                    input {
                        background-color: transparent;
                        color: #fff;
                        margin: 0 15px
                    }

                    .icon-search_box {
                        right: 20px;
                        color: #fff
                    }
                }

                ul {
                    li {
                        margin-top: 10px;

                        a {
                            color: #fff;

                            &.active {
                                background-color: #bcbcbc82 !important;
                            }
                        }

                        .menu-child {
                            width: 90%;
                            left: 10%;
                            background-color: #bcbcbc1a;

                            ul {
                                &::after {
                                    display: none;
                                }
                            }

                            &::after {
                                display: none;
                            }
                        }

                        &::after {
                            display: none;
                            height: 80%;
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        .boxThongKe {
            .box-chart {
                &::after {
                    display: none;
                }

                .option-top {
                    width: 100% !important;
                }

                .box-double-chart {
                    flex-wrap: wrap;
                    flex-direction: column;

                    .box-char-left,
                    .box-char-right {
                        margin: 0;
                        flex-grow: 1;
                        margin-top: 10px;
                        width: 100%;

                        .title-top {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .mainBoxSlideThongKe {
                padding: 15px 0;

                .boxSlideThongKe {
                    .itemBoxSlideThongKe {
                        border: 0px;
                        padding: 0 20px;

                        .title-top {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .boxSPBaoCaoViPham {
            margin-bottom: 25px;
        }

        .navbar-toggler-icon {
            color: black;
        }

        .header-page {
            padding: 10px 0 10px;

            .header_box-top {
                padding: 0 0 10px;

                .box-col-right, .inp-custom-nav {
                    margin-left: auto;
                    margin-left: auto;
                    margin-right: 0px;
                }
            }

            .header_box-center {
                margin-top: 0px;
            }

            .col-left {
                width: 100%;
            }
        }

        .box_dangky-dangnhap {
            min-height: 400px;

            .col-left {
                .from-inp {
                    margin-bottom: 10px;

                    .box-inp {
                        width: 100% !important;
                    }
                }
            }

            .col-right {
                .box-dang-ky {
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }

        .boxMainRow {
            &::after {
                content: "";
                width: 100% !important;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: #b3b3b34d;
                position: absolute;
                right: 0;
                margin: auto;
            }
        }

        .boxDoanhNghiepTichCuc.boxMainRow {
            &::after {
                content: "";
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: #a80713;
                position: absolute;
                right: 0;
                margin: auto;
            }
        }

        footer {
            .box-main_footer {
                flex-wrap: wrap;
                padding: 20px 0;

                .itemBoxFooter {
                    .box-items-full {
                        padding: 0 !important;
                    }
                }

                .box-sm-top,
                .box-sm-bottom {
                    flex-basis: auto !important;
                    margin: 10px 0;
                    padding: 10px 0;
                }

                .box-sm-double {
                    flex: 1;
                }
            }
        }
    }

    // tin tuc chi tiet
    .wrap-main-tin-tuc-chi-tiet {
        &.dSach-hoi-dap {
            .col-left {
                .col-left_danh-sach-hoi-dap {
                    .box-filter-dsachHoiDap {
                        .filter-dsachHoiDap-top {
                            .col-right {
                                .form-inline{
                                    input{
                                        margin-top: 10px
                                    }
                                    button{
                                        width:initial !important
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .box-tin-lien-quan {
            .list-tin-lien-quan {
                flex-wrap: wrap;

                .item-tin {
                    width: 100% !important;
                    margin-top: 20px !important;
                    img {
                        min-height: auto !important;
                        width: 100% !important;
                    }
                }
            }
        }

        &.dSach-hoi-dap {
            .box-filter-dsachHoiDap {
                .filter-dsachHoiDap-top {
                    flex-wrap: wrap;

                    button,
                    .col-right {
                        width: 100% !important;
                        margin-right: 0 !important
                    }

                    .col-right {
                        margin-top: 20px;
                        margin-left: 0 !important
                    }
                }

                .filter-dsachHoiDap-bottom {
                    .field_search {
                        flex-wrap: wrap;

                        .item-filter:first-child {
                            width: 100%;
                        }

                        .item-filter {
                            width: 50%;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .box-list-commnent-user {
                .box-list-content-comment {
                    .binh-luan-cha {
                        .btn-setting {
                            .list-menu {
                                width: 50%;

                                &.active {
                                    background-color: #bcbcbc;
                                }

                                ul {
                                    flex-wrap: wrap;

                                    &::after {
                                        display: none;
                                    }

                                    li {
                                        width: 100%;
                                        border-bottom: 1px solid #fff;

                                        a {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .binh-luan-con {
                        .box-see-more {
                            flex-wrap: wrap;
                        }

                        .feedBack-box {
                            margin-left: auto;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }


    //danh sach tin tuc
    .wrap-main-tin-tuc {
        .col-left {
            .itemNewsLater {
                .col-left{
                    margin-right: 0px !important
                }
            }
        }

        .from-submit {
            padding: 0px !important;

            .col-left, .col-right {
                padding: 0px !important;
                margin-top: 10px;
            }
        }

        .title-top {
            text-align: left !important;
            padding: 0px !important;
        }

        .input-text-sb {
            padding: 0px !important;
        }

        .col-left {
            .itemNewsTop {
                flex-wrap: wrap !important;
                display: flex;

                .col-right {
                    margin-left: 0px !important;
                    order: 1;
                    width: 100% !important;
                    flex: 1 1 auto !important;

                    img {
                        max-height: 250px !important;
                        min-height: 250px !important;
                    }
                }

                .col-left {
                    order: 2;
                    width: 100% !important;
                    flex: 1 1 auto !important;
                }
            }

            .itemNewsLater {
                flex-direction: column;

                .col-left {
                    max-width: 100% !important
                }
            }

            .box_SpBaoCaoViPham {
                .item-SanPhamBaoCao {
                    width: calc(100% / 2 - 10px) !important;
                    margin-top: 10px;
                }
            }
        }
    }
    .wrap-main-nhanDienThuongHieu {
        padding: 20px 0;

        form {
            display: flex;
            flex-direction: column;

            .filter-top {
                order: 2;
                flex-wrap: wrap;
                margin-bottom: 10px;

                .items-col-filter {
                    width: 100%;
                    margin-top: 10px;
                }
            }

            .filter-bottom {
                order: 1;
                justify-content: space-between;

                .items-bottom-col-filter {
                    width: 48%;
                    margin-right: 0px
                }
            }
        }

        .main-tab-content {
            margin-top: 20px;

            .list-group-item-action {
                font-size: 12px;
                padding: 5px;
            }
        }

        .box-filter-main {

            .box-filter-main_top,
            .box-filter-main_bottom {
                flex-wrap: wrap;

                .col-left,
                .col-right {
                    margin-left: 0px;
                    margin-right: 0px;
                }

                .col-right {
                    margin-top: 20px;
                }
            }

            .box-filter-main_bottom {
                .col-right {
                    margin-top: 10px;
                }
            }

            .boxBoLocNangCao {
                margin-top: 20px !important;
            }
        }

        .box-list-san-pham {
            .border-image {
                width: 50% !important;
                margin-right: 10px;
                min-height: auto !important;
                max-height: 100% !important;
            }

            .item-sanPham {
                width: 100% !important;
            }

            .item-sanPhamborder {
                width: 100% !important;
            }
        }

        .box_SpBaoCaoViPham {
            .item-SanPhamBaoCao {
                width: 50%;
                .box-image{
                    height: auto !important;
                    a{
                        width: 100% !important;
                        img{
                            width: 100% !important
                        }
                    }
                }
            }
        }

        .box_ThuongHieuNB {
            &::after {
                display: none;
            }

            .col-left, .col-right {
                width: 100% !important;
                padding-left: 0px;
                padding-right: 0px;
            }

            .col-left {
                padding: 10px 0 20px
            }

            .col-right {
                padding: 20px 0;
                border-top: 1px solid #bcbcbc82;
            }
        }

        .boxSPBaoCaoViPham {
            margin: 0;
            padding: 30px 0 0;

            .item-SanPhamBaoCao {
                .mb-2 {
                    min-width: 100% !important;
                }
            }

            .item {
                width: 190% !important;
                margin-top: 20px;
            }
        }

        .box-thuong-hieu-noi-bat-va-xu-huong-tim-kiem {
            flex-wrap: wrap;

            .boxThuongHieuNoiBat {
                width: 100% !important;
                padding-bottom: 10px;
                border-bottom: 1px solid #bcbcbc;
            }

            .box-xu-huong-tim-kiem {
                margin-left: 0px !important;
                width: 100% !important;
                padding-top: 10px;
            }

            &::after {
                display: none;
            }
        }
    }
}



// Start CSS file Danh sách bài viết
@media(max-width: 767.98px) {
    .wrap-main-danh-sach-bai-viet {
      padding: 20px 0;
  
      .col-left {
        .box-dang-bai {
          border-radius: 0px;
        }
  
        .box-select-option {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          flex-wrap: wrap;
  
          .col-right {
            margin: auto;
          }
        }
  
        .box-bai-viet-cua-ban {
          .col-right {
            .btn-setting {
              .box-setting-btn {
                width: 200% !important;
                left: -100% !important;
                background-color: #bcbcbc !important;
                ul {
                  &::after {
                    display: none;
                  }
                }
              }
            }
  
            .box-content {
              &::after{
                display: none;
              }
              &::before{
                display: none;
              }
            }
            .box-bottom{
              flex-wrap: wrap;
              .col-left,.col-right{
                width: 100% !important;
              }
            }
          }
        }
      }
    }
    .wrap-main-chat .main-col-right .chat_box .chat_box-top {
        max-height: 65vh;
    }

    .wrap-main-chat .main-col-right .chat_box .chat_box-top .item-chat {
        width: 100%;
    }

    .wrap-main-chat .main-col-right .chat_box .chat_box-top .item-chat.customer {
        background-color: #6495ed9e;
    }

    .table-responsive .table .rps-table-col-none {
        display:none;
    }
  }
// End CSS file Danh sách bài viết
/// trang cá nhân
@media(max-width: 767.98px) {
    .wrap-main-trang-ca-nhan {
        .box-detail-user {
            .box-detail-user_child {
                flex-wrap: wrap;

                .col-left, .col-right {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }

                .col-right {
                    margin-top: 20px;

                    .box-main-bottom {
                        .btn-pencil {
                            display: block;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .box-thong-tin-ca-nhan {
            .box-thong-tin-ca-nhan_detail {
                .col-left, .col-right {
                    width: 100%;
                }
            }
        }

        .box-thong-tin-ket-noi {
            .content {
                flex-wrap: wrap;

                .col-left {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
    }
}