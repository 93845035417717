* {
    margin: 0;
    padding: 0;
}

.wrap-main .owl-carousel .owl-nav button.owl-next:hover,
.wrap-main .owl-carousel .owl-nav button.owl-prev:hover {
    background: transparent !important;
}

.wrap-main .owl-nav .owl-next i {
    font-size: 30px !important;
}

.list-item-chatBox {
    border: 0px !important;

    &:hover {
        background-color: #f2f5fa !important;
    }

    &.active {
        background-color: #f2f5fa !important;
    }
}

.main-tab-content {
    .list-group-item {
        z-index: 0;
    }
}

.box-title_main {
    padding: 10px;
    background-color: #bcbcbc82;
    border-radius: 3px;
    margin-bottom: 10px;
}

.box-ket-noi {
    .boxMainUser {
        justify-content: space-between;

        .userBox {
            width: 50%;
            margin-bottom: 10px;
        }
    }
}

.box_traoDoiMoiNhat {
    .newsItem {
        padding: 20px 0;
        border-bottom: 1px solid #bcbcbc1a;

        a {
            color: black;
        }
    }
}

.search-form-wrap {
    .items-col-filter {
        width: calc(100% / 4 - 20px);
        margin: auto;
        justify-content: space-between;
        text-align: center;
    }

    .items-bottom-col-filter {
        margin-top: 10px;
        width: calc(100% / 3 - 20px);
        margin-right: 20px;
        justify-content: space-between;

        p {
            width: 100%;
        }
    }

    .dropdown {
        button {
            border: 1px solid #bcbcbc82;
            border-radius: 5px;
            width: 100%;
        }
    }
}

.box-chart-guest {
    .time-request {
        text-align: right;
    }
}

.content-tienich {
    img {
        width: 100%;
    }
}

.form-select.form-control .dropdown.radio-select .tag-list .tag-item .tag, .form-select.form-control .dropdown.simple-select .tag-list .tag-item .tag {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@font-face {
    font-family: "Roboto-LightItalic";
    src: url("../../assets/Roboto/Roboto-LightItalic.ttf");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("../../assets/Roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto-Light";
    src: url("../../assets/Roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("../../assets/Roboto/Roboto-Bold.ttf");
}

body {
    overflow: visible !important;
    background-color: rgba(188, 188, 188, 0.2) !important;
}

.box-modal-DKTC {
    overflow: auto !important;

    &::-webkit-scrollbar {
        width: 1px;
    }
}

.font-Light {
    font-family: "Roboto-Light";
}

.font-LightItalic {
    font-family: "Roboto-LightItalic";
}

.font-Regular {
    font-family: "Roboto-Regular";
}

.font-Bold {
    font-family: "Roboto-Bold";
}

.font-normal {
    font-size: 14px;
}

.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-30 {
    font-size: 30px;
}

.limited-line-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.limited-line-7 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.text-link {
    text-decoration: none;
}

.limited-line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.limited-line-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.limited-line-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.limited-line-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.color-black {
    color: #333333;
}

.color-gray {
    color: #b3b3b3;
}

.btn-primary {
    background-color: #044587 !important;
    border-color: #044587 !important;
}

.btn-danger {
    background-color: #a80713 !important;
    border-color: #a80713 !important;
}

.color-danger {
    color: #a80713 !important;
}

.color-success {
    color: #009245;
}

.color-blue {
    color: #044587;
}

.wrap-main {
    /*    max-width: 1366px;*/
    margin: auto;
    background-color: #fff;

    .inp-custom-nav {
        position: relative;
        margin-right: 10px;
        opacity: 0.5;

        input {
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #fff;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: red;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: red;
            }
        }

        button {
            top: 0;
            bottom: 0;
            margin: auto;
            right: 0px;
            position: absolute;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: #fff !important;
            }
        }
    }

    .binh-luan-cha {
        margin-bottom: 15px;
        background-color: #bcbcbc1a;
        border-radius: 10px;
        padding: 10px
    }

    .color-white {
        color: #fff;
    }

    .bg-white {
        background-color: #fff;
    }

    .title-box-lg {
        border-left: 3px solid;
        color: #044587;
        padding-left: 20px;

        p {
            color: #044587;
        }
    }

    .title-bg-lg {
        color: #044587;
        padding-left: 20px;
        padding: 13px;
        background-color: #b3b3b380;
        padding-left: 30px;

        p {
            color: #044587;
            text-transform: uppercase;
        }
    }

    .max-image {
        width: 100%;
        height: 160px;
    }

    .crop-image {
        width: 60px !important;
        height: 50px;
    }

    .line-center-double {
        position: absolute;
        width: 1px;
        background-color: #b3b3b34a;
        height: 98%;
        left: 0;
        right: 0;
        top: -10px;
        margin: auto;
        bottom: 0;
    }
    //owl nav
    .owl-nav {
        margin-top: 0px !important;
        position: absolute;
        top: -60px;
        width: 35px;
        right: 0;
        justify-content: space-between;
        align-items: center;
        display: flex !important;

        &.disabled {
            display: flex !important;
        }

        .owl-prev,
        .owl-next {
            outline: 0;

            i {
                font-size: 30px;
                color: #b3b3b3;
            }
        }
    }

    .box-title_main_blue {
        margin: 30px 0px 20px;
        padding: 10px 10px;
        background-color: #044587;
        border-radius: 5px;
    }

    .box-title_main_gray {
        margin: 30px 0px 20px;
        padding: 10px 10px;
        background-color: #bcbcbc82;
        border-radius: 5px;
    }

    .boxSPBaoCaoViPham {
        .boxSlideSpBaoCaoViPham {
            .owl-nav {
                top: -60px;
            }
        }
    }
    //header all page
    .header-page {
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px 0 15px;

        .header_box-top {
            border-bottom: 1px solid #fff;
            padding: 0 0 10px;

            .navbar-nav {
                .nav-item {
                    min-width: 20px;

                    .inp-custom-nav {
                        position: relative;

                        input {
                            &::placeholder {
                                /* Chrome, Firefox, Opera, Safari 10.1+ */
                                color: #fff;
                                opacity: 1; /* Firefox */
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10-11 */
                                color: red;
                            }

                            &::-ms-input-placeholder {
                                /* Microsoft Edge */
                                color: red;
                            }
                        }

                        button {
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            right: 10px;
                            content: "";
                            position: absolute;
                            color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .dropdown-menu-lg.dropdown-menu.dropdown-menu-right {
                        min-width: 270px;
                        padding: 0;
                        z-index: 99999;
                    }

                    a {
                        position: relative;

                        i {
                            font-size: 20px;
                        }

                        span {
                            &.badge.badge-danger.navbar-badge.rounded-circle {
                                top: 0;
                                right: 0;
                                position: absolute;
                            }

                            &.d-none.d-md-inline {
                                position: relative;
                            }
                        }
                    }

                    &.user-menu {
                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .user-image {
                                width: 25px;
                            }
                        }
                    }
                }
            }
        }

        .col-left {
            width: 150px;
        }

        .col-right {
            width: calc(100% - 18%);
            // margin-top: auto;
            // margin-bottom: auto;
            // margin-left: auto;
            margin: auto;
        }

        .header_box-center {
            margin-top: 10px;

            .title-style-bold {
                text-transform: uppercase;
            }

            .title_box-left {
                font-size: 26px;
                font-family: "Roboto-Regular";
                text-transform: uppercase;
                text-align: center;
            }
        }
    }
    //nav-menu
    .nav-menu {
        border-bottom: 3px solid #044587;

        .navbar-nav {
            position: relative;

            &::after {
                content: "";
                width: 3px;
                height: 100%;
                background-color: #fff;
                right: 3px;
                top: 0;
                position: absolute;
            }
        }

        a.font-Bold {
            font-size: 14px;
            color: black;
        }

        .box-item-nav {
            position: relative;

            .nav-link {
                &.active {
                    color: #044587;
                }
            }

            &:hover .menu-child {
                display: block;
            }

            .menu-child {
                width: 200px;
                border-radius: 5px;
                position: absolute;
                z-index: 1;
                background-color: #b21f2dcc;
                top: 95%;
                display: none;

                &::after {
                    content: "";
                    position: absolute;
                    border-left: 10px solid #b21f2dcc;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-top: 10px solid transparent;
                    -webkit-transform: rotate(270deg);
                    -ms-transform: rotate(270deg);
                    transform: rotate(270deg);
                    top: -20px;
                    left: 23px;
                }
                // &:hover .list_menu-child {
                //   display: block;
                // }
                .list_menu-child {
                    list-style-type: none;
                    position: relative;
                    // display: none;
                    &::after {
                        content: "";
                        position: absolute;
                        width: calc(100% - 20px);
                        height: 1px;
                        background-color: #b21f2d;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }

                    li {
                        padding: 10px 10px;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            width: calc(100% - 20px);
                            height: 1px;
                            background-color: #ffffff;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }

                        &:hover {
                            background-color: #ffffff33;
                        }

                        a,
                        p {
                            text-decoration: none;
                            color: #fff;
                            text-transform: uppercase;
                            font-family: "Roboto-Regular";
                            margin: 0;
                        }

                        &:hover .menu-lever-child {
                            display: block;
                        }

                        .menu-lever-child {
                            display: none;
                            position: absolute;
                            top: 0;
                            background-color: #b21f2dcc;
                            width: 100%;
                            list-style-type: none;
                            right: -100%;
                            border-radius: 5px;

                            &::after {
                                content: "";
                                position: absolute;
                                width: calc(100% - 20px);
                                height: 1px;
                                background-color: #b21f2d;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                            }

                            li {
                                a {
                                    text-decoration: none;
                                    color: #fff;
                                    text-transform: uppercase;
                                    font-family: "Roboto-Regular";
                                    margin: 0;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            &::after {
                position: absolute;
                content: "";
                right: 3px;
                top: 0;
                width: 3px;
                background-color: black;
                bottom: 0;
                margin: auto;
                font-weight: bold;
                font-size: 16px;
                height: 12px;
            }
        }

        .icon-search_box {
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    //box thong ke
    .boxThongKe {
        .box-option-slc {
            z-index: 10;
        }

        .boxSlideThongKeTQ {
            margin-top: 15px;
        }

        .titleMainBox {
            position: relative;

            &::after {
                position: absolute;
                content: "";
                top: 0;
                width: 60%;
                background-color: #044587;
                height: 3px;
            }

            &::before {
                position: absolute;
                content: "";
                bottom: 0;
                width: 60%;
                background-color: #044587;
                height: 3px;
            }

            p {
                padding: 10px 0;
            }
        }

        .box-chart {
            position: relative;

            &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 100%;
                background-color: #b3b3b34a;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
            }

            .box-char-left,
            .box-char-right {
                flex: 1;
            }

            .box-char-right {
                margin-left: 30px;
                display: flex;
                flex-direction: column;

                .sub-title-chartPie {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;
                    margin-left: 25px;
                }
            }

            .box-char-left {
                margin-right: 30px;
                position: relative;

                &::after {
                    position: absolute;
                    content: "Số vụ";
                    font-size: 12px;
                    color: #777;
                    top: 62px;
                    left: 0px;
                    font-family: "Roboto-Regular";
                }
            }
        }

        .option-top {
        }

        .mainBoxSlideThongKe {
            position: relative;
            min-height: 107px;

            .slick-slider {
                border: 1px solid #bcbcbc82;
                border-radius: 10px;

                .slick-slide {
                    padding: 0px;
                }
            }

            .boxSlideThongKe {
                position: relative;
                margin: 0px !important;

                .itemBoxSlideThongKe {
                    position: relative;
                    padding: 10px;
                    border-right: 1px solid #bcbcbc82;
                    padding-left: 40px;

                    &:hover .boxHoverItemSlideThongKe {
                        display: block;
                        z-index: 10;
                    }

                    .boxHoverItemSlideThongKe {
                        background-color: #a80713e6;
                        position: absolute;
                        width: 100%;
                        top: -270px;
                        min-height: 270px;
                        left: 0;
                        border-radius: 5px;
                        display: none;

                        &::after {
                            position: absolute;
                            content: "";
                            bottom: -8px;
                            left: 30px;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-bottom: 8px solid #a80713;
                            transform: rotate(180deg);
                        }
                    }

                    .title-top {
                        white-space: pre-line;
                    }
                }
                // new slide
                .slick-list {
                    z-index: 0;
                    /*border: 1px solid #bcbcbc82;*/
                    border-radius: 10px;

                    &:hover {
                        padding-top: 270px;
                        margin-top: -270px;

                        &::after {
                            /* position: absolute;
                            content: "";
                            width: 4px;
                            height: 100%;
                            right: 3px;
                            bottom: 0;
                            background-color: #fff;*/
                        }
                    }

                    &::after {
                        /* position: absolute;
                        content: "";
                        width: 4px;
                        height: 100%;
                        right: 2px;
                        bottom: 0;
                        background-color: #fff;*/
                    }
                }

                .slick-arrow {
                    top: 0;
                    bottom: 0;
                    margin: auto;

                    &.slick-prev {
                        left: -25px;

                        &::before {
                            content: "\f104";
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            color: #bcbcbc;
                            font-size: 30px;
                        }
                    }

                    &.slick-next {
                        right: -25px;

                        &::before {
                            content: "\f105";
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            color: #bcbcbc;
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }

    .boxThongKeTruyCap {


        .mainBoxSlideThongKe {
            position: relative;




            .boxSlideThongKeTruyCap {


                .itemBoxSlideThongKe {
                    position: relative;

                    &:hover .boxHoverItemSlideThongKe {
                        display: block;
                        z-index: 10;
                    }

                    .boxHoverItemSlideThongKe {
                        background-color: #a80713e6;
                        position: absolute;
                        width: 100%;
                        top: -270px;
                        min-height: 270px;
                        left: 0;
                        border-radius: 5px;
                        display: none;

                        &::after {
                            position: absolute;
                            content: "";
                            bottom: -8px;
                            left: 30px;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-bottom: 8px solid #a80713;
                            transform: rotate(180deg);
                        }
                    }

                    .title-top {
                        white-space: pre-line;
                    }
                }
            }
        }
    }
    //form dang ky va dang nhap
    .box_dangky-dangnhap {
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 3px solid #a80713;

        &::after {
            // content: "";
            // width: 1px;
            // height: 80%;
            // margin: auto;
            // left: 0;
            // right: 0;
            // top: 0;
            // background-color: #bcbcbc;
            // position: absolute;
            // bottom: 0;
        }

        .col-left {
            margin-left: auto;

            .from-inp {
                margin-bottom: 15px;
            }
        }

        .col-right {
            margin-right: auto;
        }
    }
    //component doanh nghiep vi pham
    .boxDoanhNghiepViPham {
        background-color: #f4f6f9;
        padding: 40px 0;

        .boxSlideDoanhNghiepViPham {
            position: relative;

            &::after {
                content: "";
                width: 1px;
                background-color: #f4f6f9;
                right: 0;
                height: 100%;
                top: 0;
                position: absolute;
            }

            .boxItemDoanhNghiepViPham {
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    background-color: rgba(179, 179, 179, 0.3);
                    height: 100%;
                    top: 0;
                    left: -10px;
                }
            }
        }
    }
    //component sp bao cao vi pham va dia ban xay ra vi pham
    .boxMainRow {
        padding: 40px 0;
        position: relative;

        &::after {
            content: "";
            width: 1130px;
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: #b3b3b34d;
            position: absolute;
            right: 0;
            margin: auto;
        }

        .col-left {
            margin-right: auto;
        }

        .col-right {
            margin-left: auto;
        }
    }
    // component vu viec vi pham va hoi dap
    .boxVuViecViPhamVaHoiDap {
        &.boxMainRow {
            padding: 40px 0 0;
            background-color: #f4f6f9;
        }

        .boxSildeHoiDap {
            border-top: 1px solid #b3b3b34b;

            .owl-nav {
                top: -41px !important;
                right: 30px !important;
                margin-top: 0px !important;
            }

            .bg-itemBoxSildeHoiDap {
                background-color: #f4f6f9;
                padding: 30px;
            }
        }
    }
    //component doanh nghiep tich cuc
    .boxDoanhNghiepTichCuc {
        &.boxMainRow {
            &::after {
                content: "";
                width: 1130px;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: #b3b3b34d;
                position: absolute;
                right: 0;
                margin: auto;
            }
        }

        .boxSlideDoanhNghiepTichCuc {
            .itemSlideDoanhNghiepTichCuc {
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    background-color: #b3b3b34d;
                    height: 100%;
                    top: 0;
                    left: -20px;
                }
            }
        }
    }
    // component box slide doi tac
    .boxSlideDoiTac {
        .slideDoiTac {
            padding: 0 30px;

            .owl-nav {
                position: initial;
                top: initial;
                width: 100%;
                right: initial;
                margin-top: 0px !important;

                .owl-prev {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                }

                .owl-next {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }

            .itemSlideDoiTac {
                width: 100%;
                height: 166px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    padding: 20px;
                }
            }
        }
    }

    .boxNhanDienThuongHieu {
        .boxSlideNhanDienThuongHieu {
            .owl-nav {
                position: initial;
                top: initial;
                width: 100%;
                right: initial;
                margin-top: 0px !important;

                .owl-prev {
                    position: absolute;
                    left: 0;
                    top: -150px;
                    bottom: 0;
                }

                .owl-next {
                    position: absolute;
                    right: 0;
                    top: -150px;
                    bottom: 0;
                }
            }
        }
    }

    .boxThongKe {
        &.boxMainRow {
            &::after {
                content: "";
                width: 1130px;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: #a80713;
                position: absolute;
                right: 0;
                margin: auto;
            }
        }
    }

    .boxVuViecViPhamVaHoiDap {
        &.boxMainRow {
            &::after {
                content: "";
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: #b3b3b34d;
                position: absolute;
                right: 0;
                margin: auto;
            }
        }
    }

    .boxPhanAnhViPham {
        &.boxMainRow {
            padding: 10px;
            padding-bottom: 30px;
            font-size: 12px;
            font-family: 'Roboto-Regular';
            color: #333333;

            .title {
                font-family: 'Roboto-Bold';
                text-transform: uppercase;
                color: #333333;
            }

            .font-bold {
                font-family: 'Roboto-Bold';
            }

            .box-action i {
                font-size: 18px;
                color: #999999;
            }
        }
    }

    .boxPhanAnhViPham {
        &.boxMainRow {
            &::after {
                content: "";
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: #b3b3b34d;
                position: absolute;
                right: 0;
                margin: auto;
            }
        }
    }

    .boxPhanAnhViPham .col-sm-3 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .boxNhanDienThuongHieu {
        &.boxMainRow {
            &::after {
                height: 0px;
            }
        }
    }
    //footer
    footer {
        background-color: #044587;
        min-height: 100px;

        .box-main_footer {
            padding: 30px 0 66px;

            .itemBoxFooter {
                min-height: 50px;
                width: 100%;

                &.box-lg {
                    flex: 3;
                }

                &.box-medium {
                    flex: 3;
                }

                &.box-sm {
                    flex: 1;
                }

                .box-items-full {
                    padding: 0 15px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    li {
                        list-style-type: none;
                    }
                }
            }
        }
    }

    .font-26 {
        font-size: 26px;
    }

    .font-20 {
        font-size: 20px;
    }

    .color-gray {
        color: #bcbcbc;
    }

    .wrap-main-tin-tuc {
        .col-left {
            //itemNewsTop
            .itemNewsTop {
                display: flex;
                padding: 30px 0 20px;
                border-bottom: 1px solid #bcbcbc82;

                .col-left {
                    flex: 1;

                    .title-top {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: 22px;
                        /* max-height: 32px; */
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                    }

                    min-height: 100px;

                    .tieu-de {
                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            line-height: 22px;
                            /* max-height: 32px; */
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                        }
                    }

                    .solical {
                        margin-top: 50px;

                        .item-solical {
                            flex: 1;
                        }
                    }
                }

                .col-right {
                    flex: 1.5;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 20px;

                    img {
                        min-height: 350px;
                        max-height: 350px;
                    }
                }
            }
            //itemNewsLater
            .itemNewsLater {
                min-height: 50px;
                padding: 20px 0;
                display: flex;
                border-bottom: 1px solid #bcbcbc82;

                .col-left {
                    flex: 1;
                    border-radius: 5px;
                    overflow: hidden;
                    margin-right: 20px;
                }

                .col-right {
                    flex: 3;

                    .content_column-right {
                        overflow: hidden;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: 22px;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            //pagination-page
            .pagination-page {
                padding: 20px 0;
                border-bottom: 1px solid #bcbcbc82;
            }
            //box_SpBaoCaoViPham
            .box_SpBaoCaoViPham {
                display: flex;
                flex-wrap: wrap;
                padding: 30px 0;
                justify-content: space-between;

                .item-SanPhamBaoCao {
                    width: calc(100% / 4 - 10px);
                    padding: 5px;
                }
            }
        }

        .col-right {
            .box-title_main {
                margin: 30px 0px 20px;
                padding: 10px 10px;
                background-color: #bcbcbc82;
                border-radius: 5px;
            }

            .box_traoDoiMoiNhat {
                .newsItem {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #bcbcbc82;
                }
            }

            .box-ket-noi {
                .boxMainUser {
                    border-bottom: 1px solid #bcbcbc82;

                    .userBox {
                        width: 50%;
                        margin-bottom: 10px;
                    }
                }
            }

            .box-coTheBanQuanTam {
                .boxMainUser {
                    border-bottom: 1px solid #bcbcbc82;
                    padding-bottom: 20px;

                    .userBox {
                        width: 50%;
                    }
                }
            }

            .box-suKienSapDienRa {
                padding: 10px 0;

                .item-suKienSapDienRa {
                    .col-left {
                        width: 40%;
                    }

                    .col-right {
                        width: 50%;
                    }
                }
            }
        }

        .box-comment-user {
            margin-top: 20px;
            padding: 40px 0 0 0;
            border-top: 1px solid #bcbcbc82;
            // border-bottom: 1px solid #bcbcbc82;
            .box-comment_bottom {
                border: 1px solid #828282bc;
                border-radius: 5px;
                padding: 7px;
                min-height: 80px;

                .box-comment {
                    textarea {
                        border: 0px;
                        padding: 15px;

                        &:focus {
                            border: none;
                            overflow: auto;
                            outline: none;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                            resize: none;
                        }
                    }
                }
            }
        }
    }
    // nhận diện thương hiệu
}
//Style danh sách tin tức
// content main wrap
//Style chi tiet tin tuc
.wrap-main-tin-tuc-chi-tiet {
    text-align: justify;

    .font-10 {
        font-size: 10px;
    }

    .date_solical {
        .col-solical {
            .item-solical {
                width: 70px;
            }
        }
    }

    .box-content_news {
        .content-img-lg {
            width: 100%;
            min-height: 520px;
            max-height: 520px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .box-comment-user {
        margin-top: 20px;
        padding: 40px 0;
        border-top: 1px solid #bcbcbc82;
        border-bottom: 1px solid #bcbcbc82;

        .box-comment_bottom {
            border: 1px solid #828282bc;
            border-radius: 5px;
            padding: 7px;
            min-height: 80px;

            .box-comment {
                textarea {
                    border: 0px;
                    padding: 15px;

                    &:focus {
                        border: none;
                        overflow: auto;
                        outline: none;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        resize: none;
                    }
                }
            }
        }
    }

    .box-list-commnent-user {
        .box-list-content-comment {
            .binh-luan-cha {
                .content-binh-luan {
                    margin-left: 35px;
                    /*min-height: 80px;*/
                    border-radius: 5px;
                    background-color: #cccccc36;
                }
            }

            .binh-luan-con {
                margin-left: 35px;
                margin-top: 10px;
                border-radius: 10px;
                background-color: #fff;
                padding: 10px;

                .content-binh-luan {
                    margin-left: 35px;
                    /*min-height: 80px;*/
                    border-radius: 5px;
                    background-color: #cccccc36;
                }
            }
        }

        .box-see-more {
            margin-left: 35px;
        }
    }

    .box-tin-lien-quan {
        margin: 50px 0 0;

        .list-tin-lien-quan {
            .item-tin {
                width: calc(100% / 4 - 15px);
                min-height: 30px;

                img {
                    min-height: 160px;
                }

                .title-tin {
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 18px;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    text-align: justify;
                }
            }
        }
    }
}
//end style chi tiet tin tuc
.donvi-tochuc p::before {
    color: #e30616;
    content: "●";
    margin-right: 5px;
}

.icon-thamdu :hover,
.icon-quantam :hover,
.icon-chiase :hover,
.icon-action :hover,
.icon-active {
    color: #044587;
    cursor: pointer;
}
//Style hỏi đáp
input {
    &:focus {
        /*box-shadow: 0 0 0 0 !important;
        border-color: none;
        border: none;*/
    }
}

.col-left_danh-sach-hoi-dap {
    overflow: hidden;

    .box-filter-dsachHoiDap {
        padding: 30px 0 20px;
        border-bottom: 3px solid #044587;

        .filter-dsachHoiDap-top {
            .col-right {
                position: relative;

                input.form-control {
                    &:focus {
                        box-shadow: 0 0 0 0 !important;
                    }
                }
            }
        }

        .filter-dsachHoiDap-bottom {
            .item-filter {
                width: calc(100% / 5);
            }
        }
    }

    .box-list-commnent-user {
        .box-list-content-comment {
            padding: 10px 0 30px;
            border-bottom: 3px solid #044587;

            &.box-ket-qua-search {
                border-bottom: 1px solid #bcbcbc82 !important;
            }

            .binh-luan-cha {
                .box-binh-luan-cha {
                    position: relative;

                    .btn-setting {
                        .icon-setting {
                            position: absolute;
                            right: 0;
                        }

                        .list-menu {
                            border: 1px solid #bcbcbc82;
                            padding-right: 30px;
                            border-radius: 5px;
                            position: absolute;
                            right: -100%;
                            top: 0;
                            transition: all ease-in-out 1s;

                            &.active {
                                right: -10px;
                                background-color: #bcbcbc82;
                            }

                            ul {
                                position: relative;

                                &::after {
                                    content: "";
                                    width: 1px;
                                    height: 100%;
                                    right: 0px;
                                    background-color: #fff;
                                    position: absolute;
                                }

                                li {
                                    padding: 0 10px;
                                    position: relative;

                                    &::after {
                                        content: "";
                                        width: 1px;
                                        height: 60%;
                                        right: 0px;
                                        background-color: #ccc;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .box-comment {
                padding: 5px 5px;
                border: 1px solid #bcbcbc82;
                margin-left: 35px;
                border-radius: 5px;
                margin-top: 20px;
            }
        }
    }

    .pagination {
        padding: 20px 0 30px;
    }
}
//End style hỏi đáp
.su-kien .slick-slider {
    margin: 0 !important;
}

.color-gray {
    color: #808080;
}

.wrap-main-nhanDienThuongHieu {
    padding: 20px 0 50px;

    .box-filter-main {
        padding: 10px 0 10px;
        border-bottom: 3px solid #044587;

        .box-filter-main_top {
            .col-left,
            .col-right {
                flex: 1;
            }

            .col-left {
                margin-right: 18px;
                position: relative;

                .inp-search {
                    height: 35px;
                }

                .btn-search {
                    position: absolute;
                    bottom: 7px;
                    right: 13px;
                    margin: auto;
                }
            }

            .col-right {
                margin-left: 18px;
            }
        }

        .box-filter-main_bottom {
            margin-top: 20px;

            .col-left,
            .col-right {
                flex: 1;
            }

            .col-left {
                margin-right: 18px;
            }

            .col-right {
                margin-left: 18px;
            }
        }
    }

    .box-list-san-pham {
        padding: 10px 0;

        .pagination-page {
            border-top: 1px solid #bcbcbc82;
            border-bottom: 1px solid #bcbcbc82;
        }

        justify-content: space-between;

        .item-sanPham {
            width: calc(100% / 2 - 10px);
            min-height: 30px;
            padding-bottom: 30px;
            position: relative;

            &:hover .box-option-item {
                display: block;
            }

            .box-option-item {
                display: none;
                position: absolute;
                right: 0;
                top: 0;
            }

            .info-item {
                margin-top: 10px !important;

                span {
                    margin-right: 10px !important;
                }
            }

            .col-top {
                .img-col-left {
                    border: 2px solid #bcbcbc82;
                    border-radius: 15px;
                }
            }

            .col-bottom {
                .content-item {
                    p {
                        overflow: hidden;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        // line-height: 22px;
                        /* max-height: 32px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .item-sanPhamborder {
            width: calc(100% / 2 - 10px);
            min-height: 30px;
            padding-bottom: 30px;
            padding-top: 30px;
            border-top: 1px solid #bcbcbc92;

            .col-top {
                .img-col-left {
                    border: 2px solid #bcbcbc82;
                    border-radius: 15px;
                }
            }

            .col-bottom {
                .content-item {
                    p {
                        overflow: hidden;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        // line-height: 22px;
                        /* max-height: 32px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .border-image {
            border: 1px solid #bcbcbc92;
            min-height: 200px;
            max-height: 200px;
        }
    }

    .box_SpBaoCaoViPham {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;

        .item-SanPhamBaoCao {
            width: calc(100% / 4 - 10px);
            padding: 5px;
            margin-top: 10px;

            .box-content {
                margin-top: 10px
            }

            img {
                height: 100%;
            }

            &:hover .box-option-item {
                display: block;
            }

            .box-option-item {
                display: none;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    .boxSPBaoCaoViPham {
        padding: 30px 0;

        .title_box {
            margin-bottom: 20px;
        }

        .boxSlideSpBaoCaoViPham {
            .item-SanPhamBaoCao {
            }

            .owl-nav {
                right: 10px;
                top: -46px;
            }
        }

        .item {
            width: calc(100% / 4 - 20px);
        }
    }

    .box-thuong-hieu-noi-bat-va-xu-huong-tim-kiem {
        padding: 20px 0;
    }

    .box-thuong-hieu-noi-bat-va-xu-huong-tim-kiem {
        position: relative;

        &::after {
            content: "";
            width: 1px;
            position: absolute;
            height: 100%;
            background-color: #bcbcbc82;
            left: 0;
            right: 0;
            margin: auto;
        }

        .box-thuong-hieu-noi-bat,
        .box-xu-huong-tim-kiem {
            width: 50%;
            min-height: 50px;
        }
    }

    .box-coThebanQuanTam {
        padding: 40px 0;

        .owl-nav {
            top: -42px;
        }
    }

    .box_ThuongHieuNB {
        padding: 20px 0;
        border-bottom: 1px solid #bcbcbc82;
        border-top: 1px solid #bcbcbc82;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 75%;
            background-color: #bcbcbc82;
            left: 0;
            right: -30px;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        .col-left {
            position: relative;
            margin-right: 30px;

            .owl-nav {
                right: 10px;
                top: -46px;
            }
        }

        .col-right {
            position: relative;
            padding-left: 30px;

            .owl-nav {
                right: 10px;
                top: -46px;
            }
        }
    }

    .box-list-su-kien {
        padding: 10px 0;

        .item-sanPham {
            min-height: 30px;
            padding-bottom: 30px;
            position: relative;

            &:hover .box-option-item {
                display: block;
            }

            .box-option-item {
                display: none;
                position: absolute;
                right: 0;
                top: 0;
            }

            .info-item {
                margin-top: 10px !important;

                span {
                    margin-right: 10px !important;
                }
            }

            .col-top {
                .img-col-left {
                    border: 2px solid #bcbcbc82;
                    border-radius: 15px;
                }
            }

            .col-bottom {
                .content-item {
                    p {
                        overflow: hidden;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        // line-height: 22px;
                        /* max-height: 32px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
    }
}

.wrap-main-LoiMoiThamGiaSuKien {
    padding: 0px 0 20px;

    .box-filter-main {
        padding: 10px 0 10px;
        border-bottom: 3px solid #044587;

        .box-filter-main_top {
            .col-left,
            .col-right {
                flex: 1;
            }

            .col-left {
                margin-right: 18px;
                position: relative;

                .inp-search {
                    height: 35px;
                }

                .btn-search {
                    position: absolute;
                    bottom: 7px;
                    right: 13px;
                    margin: auto;
                }
            }

            .col-right {
                margin-left: 18px;
            }
        }

        .box-filter-main_bottom {
            margin-top: 20px;

            .col-left,
            .col-right {
                flex: 1;
            }

            .col-left {
                margin-right: 18px;
            }

            .col-right {
                margin-left: 18px;
            }
        }
    }
}

.grid-pagination .pagination .select-page .page-link {
    height: 38px !important;
}
/// Tìm kiếm
.wrap-main-timKiem {
    padding: 20px 0 50px;
    width: 100%;

    .box-filter-main {
        padding: 10px 0 10px;
        /*    border-bottom: 3px solid #044587;*/
        .box-filter-main_top {
            .col-left,
            .col-right {
                flex: 1;
            }

            .col-left {
                margin-right: 18px;
                position: relative;

                .inp-search {
                    height: 35px;
                }

                .btn-search {
                    position: absolute;
                    bottom: 7px;
                    right: 13px;
                    margin: auto;
                }
            }

            .col-right {
                margin-left: 18px;
            }
        }

        .box-filter-main_bottom {
            margin-top: 20px;

            .col-left,
            .col-right {
                flex: 1;
            }

            .col-left {
                margin-right: 18px;
            }

            .col-right {
                margin-left: 18px;
            }
        }
    }

    .box-list-san-pham {
        padding: 10px 0;

        .pagination {
            margin: 15px 0;
            padding: 15px 0;
            border-top: 1px solid #bcbcbc82;
            border-bottom: 1px solid #bcbcbc82;
        }

        .item-sanPham {
            width: calc(100% / 2 - 10px);
            min-height: 30px;
            margin-top: 30px;

            .col-top {
            }

            .col-bottom {
                .content-item {
                    p {
                        overflow: hidden;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        // line-height: 22px;
                        /* max-height: 32px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .itemTimKiem {
            min-height: 50px;
            padding: 20px 0;
            display: flex;
            border-bottom: 1px solid #bcbcbc;

            .col-left {
                flex: 1;
                border-radius: 5px;
                overflow: hidden;
                margin-right: 20px;
            }

            .col-right {
                flex: 3;

                .content_column-right {
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 22px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    .boxSPBaoCaoViPham {
        padding: 10px 0;

        .item {
            width: calc(100% / 4 - 20px);
        }
    }

    .box-thuong-hieu-noi-bat-va-xu-huong-tim-kiem {
        padding: 20px 0;
    }

    .box-thuong-hieu-noi-bat-va-xu-huong-tim-kiem {
        position: relative;

        &::after {
            content: "";
            width: 1px;
            position: absolute;
            height: 100%;
            background-color: #bcbcbc82;
            left: 0;
            right: 0;
            margin: auto;
        }

        .box-thuong-hieu-noi-bat,
        .box-xu-huong-tim-kiem {
            width: 50%;
            min-height: 50px;
        }
    }

    .box-coThebanQuanTam {
        padding: 40px 0;

        .owl-nav {
            top: -42px;
        }
    }
}

.wrap-main-CTSP {
    padding: 20px 0 47px;
}

.wrap-main-CTSP .box_description-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.wrap-main-CTSP .box_description-item .col-left {
    width: 60%;
}

.wrap-main-CTSP .box_description-item .col-right {
    width: 40%;
}

.wrap-main-CTSP .box-images-product {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    img {
        width: 100%;
    }
}

.wrap-main-CTSP .box-images-product .col-left {
    width: 15%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.wrap-main-CTSP .box-images-product .col-left .item-images-thumb {
    border: 2px solid #bcbcbc;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.wrap-main-CTSP .box-images-product .slick-list {
    max-height: 400px;
}

.wrap-main-CTSP .box-images-product .col-left .item-images-thumb :focus {
    border: 2px solid #04458782;
}

.wrap-main-CTSP .box-images-product .col-left .item-images-thumb.active {
    border: 2px solid #04458782;
}

.wrap-main-CTSP .box-images-product .col-left .item-images-thumb:hover {
    background-color: #bcbcbc1a;
}

.wrap-main-CTSP .box-images-product .col-right {
    width: 85%;
}

.wrap-main-CTSP .box-mo-ta-sp {
    margin-top: 20px;
    border-top: 1px solid #bcbcbc82;
    border-bottom: 1px solid #bcbcbc82;
    padding: 20px 0;
}

.wrap-main-CTSP .box-mo-ta-sp .col-left {
    width: 40%;
}

.wrap-main-CTSP .box-mo-ta-sp-chi-tiet,
.wrap-main-CTSP .box-list-commnent-user {
    margin-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #bcbcbc82;
}

.wrap-main-CTSP .box-mo-ta-sp-chi-tiet .content-chiTietSp {
    margin-top: 20px;
}

.wrap-main-CTSP .danhgiasanphm {
    position: relative;

    &:hover .boxHover-danhgiasanpham {
        display: block;
        z-index: 10;
    }

    .boxHover-danhgiasanpham {
        background-color: rgba(179, 179, 179, 0.7);
        position: absolute;
        top: 30px;
        //min-height: 310px;
        left: 0;
        border-radius: 15px;
        display: none;

        &::after {
            position: absolute;
            content: "";
            top: -8px;
            left: 30px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid #bcbcbc;
            // transform: rotate(180deg);
        }

        .itemstar {
            padding: 10px 10px 0px 10px;
            border-bottom: 1px solid #bcbcbc;
            font-weight: bold;
        }

        .itemstar:last-child {
            font-weight: bold;
            padding: 10px 10px 0px 10px;
            border-bottom: none;
        }
    }
}

.wrap-main-ShareSP .danhgiasanphm {
    position: relative;

    &:hover .boxHover-danhgiasanpham {
        display: block;
        z-index: 10;
    }

    .boxHover-danhgiasanpham {
        background-color: rgba(179, 179, 179, 0.7);
        position: absolute;
        bottom: 0px;
        left: 90px;
        border-radius: 15px;
        display: none;

        &::after {
            position: absolute;
            content: "";
            left: -6px;
            bottom: 17%;
            border-top: 6px solid transparent;
            border-right: 6px solid #bcbcbc;
            border-bottom: 6px solid transparent;
        }

        .itemstar {
            padding: 10px 10px 0px 10px;
            border-bottom: 1px solid #bcbcbc;
            font-weight: bold;
        }

        .itemstar:last-child {
            font-weight: bold;
            padding: 10px 10px 0px 10px;
            border-bottom: none;
        }
    }
}

.wrap-main-CTSP .guibaocao {
    position: relative;

    .boxHover-guiBaoCao {
        z-index: 10;
        background-color: white;
        position: absolute;
        top: 30px;
        width: 400px;
        right: 0;
        border-radius: 5px;
        display: none;
        border: 2px solid #bcbcbc;

        &::after {
            position: absolute;
            content: "";
            top: -8px;
            right: 30px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid #bcbcbc;
            // transform: rotate(180deg);
        }
    }
}

.wrap-main-tin-tuc .guibaocao {
    position: relative;

    .boxHover-guiBaoCao {
        z-index: 10;
        background-color: white;
        position: absolute;
        top: 30px;
        width: 400px;
        right: 0;
        border-radius: 5px;
        display: none;
        border: 2px solid #bcbcbc;

        &::after {
            position: absolute;
            content: "";
            top: -8px;
            right: 30px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid #bcbcbc;
            // transform: rotate(180deg);
        }
    }
}

.wrap-main-tai-khoan .guibaocao {
    position: relative;

    .boxHover-guiBaoCao {
        z-index: 10;
        background-color: white;
        position: absolute;
        top: 30px;
        width: 400px;
        left: 0;
        border-radius: 5px;
        display: none;
        border: 2px solid #bcbcbc;

        &::after {
            position: absolute;
            content: "";
            top: -8px;
            // right: 50px;
            left: 30px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid #bcbcbc;
            // transform: rotate(180deg);
        }
    }
}


.wrap-main-tin-tuc {
    #rdts2 {
        height: 38px !important;
    }
}

.is-current {
    font-weight: 700;
    position: relative;
    color: #5488c7;
}
//Style trang ẩn danh danh sách doanh nghiệp
.enterprice-lists {
}

.enterprice-lists .item-record {
    margin-bottom: 15px;
}

.enterprice-lists .item-record .item-wrapper {
    padding: 10px;
    border: 1px solid silver;
    border-radius: 5px;
    min-height: 260px;
}

.enterprice-lists .item-record.item-left {
    padding: 0px 8px 0px 0px;
}

.enterprice-lists .item-record.item-right {
    padding: 0px 0px 0px 8px;
}

.enterprice-lists .item-record .title {
    color: #044587;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    // min-height: 70px;
}

.enterprice-lists .item-record div label {
    margin-right: 5px;
}

.wrap-main-CTSP .box-list-content-comment .content-binh-luan {
    margin-left: 35px;
    min-height: 37px;
    border-radius: 5px;
    background-color: #cccccc36;
}

.wrap-main-CTSP .box-list-content-comment .box-see-more {
    margin-left: 35px;
}

.wrap-main-CTSP .box-list-commnent-user .box-list-content-comment .binh-luan-con {
    margin-left: 35px;
    margin-top: 10px;
}

.wrap-main-CTSP .box-mo-ta-sp-chi-tiet,
.wrap-main-CTSP .box-comment-user {
    margin-top: 40px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    // border-bottom: 1px solid #bcbcbc82;
}

.wrap-main-CTSP .box-comment-user .box-comment_bottom {
    border: 1px solid #828282bc;
    border-radius: 5px;
    padding: 7px;
    min-height: 80px;
}

.wrap-main-CTSP .box-comment-user .box-comment_bottom .box-comment textarea {
    border: 0px;
    padding: 15px;
}

.wrap-main-CTSP .box-comment-user .box-comment_bottom .box-comment textarea:focus {
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
}
/*css table custom*/
table.table {
    thead {
        background-color: #044587;

        tr {
            th {
                padding: 10px;
                color: #fff;
                font-family: Roboto-Bold;
            }
        }
    }

    tbody {
        tr {
            td {
                font-family: Roboto-Light;
                padding: 10px;

                a {
                    font-family: Roboto-Regular;
                    color: #044587;
                }
            }
        }
    }
}
// style login
section .login-page-public {
    height: 100vh !important;
}

.breadcrumb {
    margin-top: 29px;
    padding: 0.6rem 1rem;
}

.content-tienich {
    text-align: justify;
}

.item-cau-hoi-tg {
    color: #044587;
    font-weight: 600;
}

.itemBoxSlideThongKe:focus {
    outline: unset !important;
}

.wrap-main .nav-menu .icon-search_box {
    padding-right: 0px;
}

.wrap-main .nav-menu {
    border-bottom: unset;
}

.nav-menu-boder {
    border-bottom: 3px solid #044587;
}
// Start CSS file Danh sách bài viết
.font-10 {
    font-size: 10px;
}

.wrap-main-danh-sach-bai-viet {
    .box-dang-bai {
        min-height: 100px;
        border: 1px solid #bcbcbc82;
        border-radius: 15px;
        overflow: hidden;

        textarea {
            &:focus {
                box-shadow: 0 0 0 0;
            }
        }

        .box-select-option {
            background-color: #044587;
            min-height: 40px;
            border: 1px solid #044587;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            padding-left: 10px;
            padding-right: 10px;

            .address {
                select {
                    background-color: #044587;
                    color: #fff;
                    border: none;
                    border: 0 !important;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    text-overflow: "";
                    padding: 5px;

                    &:focus {
                        border: none;
                    }
                }
            }
        }
    }

    .box-bai-viet-cua-ban {
        padding-bottom: 50px;
        border-bottom: 1px solid #bcbcbc82;

        .box-list-bai-viet {
            .item-post {
                width: 100%;
                margin-top: 30px;

                .col-left,
                .col-right {
                    width: calc(100% / 2 - 15px);
                }

                .col-left {
                    .box-image-bottom {
                        border-radius: 15px;
                        overflow: hidden;
                        margin-top: 15px;
                    }
                }

                .col-right {
                    .btn-setting {
                        min-height: 36px;
                        position: relative;

                        .box-setting-btn {
                            z-index: 0;
                            position: absolute;
                            left: -4px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            border-radius: 10px;
                            padding-right: 15px;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #82828282;

                            ul {
                                margin: 0;
                                position: relative;
                                list-style-type: none;

                                &::after {
                                    content: "";
                                    width: 1px;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    background-color: #bcbcbc;
                                }

                                li {
                                    padding: 0 5px;
                                    position: relative;

                                    &::after {
                                        content: "";
                                        left: 0;
                                        width: 1px;
                                        height: 80%;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                        position: absolute;
                                        background-color: #fff;
                                    }

                                    a {
                                    }
                                }
                            }
                        }
                    }

                    .box-content {
                        position: relative;

                        &::after {
                            content: "";
                            width: 100%;
                            height: 1px;
                            background-color: #bcbcbc82;
                            top: -10px;
                            position: absolute;
                        }

                        &::before {
                            content: "";
                            content: "";
                            width: 100%;
                            height: 1px;
                            background-color: #bcbcbc82;
                            bottom: -10px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }

    .box-list-commnent-user {
        .box-list-content-comment {
            border-bottom: 1px solid #bcbcbc82;

            .binh-luan-con {
                margin-left: 35px;
            }
        }
    }

    .box-list-commnent-user {
        padding: 20px 0 10px;
    }

    .box-bai-viet-moi {
        padding: 30px 0;
    }
}

.box-ChiTietSuKien {
    .DSThamDu {
        position: relative;
        /* &:hover*/
        .boxAction {
            display: block;
            border: 1px solid #cdcdcd;
            z-index: 10;
        }

        .boxAction {
            background-color: #ffffff;
            position: absolute;
            width: 200px;
            top: 40px;
            min-height: 40px;
            left: -50px;
            border-radius: 5px;
            display: none;

            &::after {
                position: absolute;
                content: "";
                top: -8px;
                right: 30px;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid #cdcdcd;
                transform: rotate(180deg);
            }
        }
        /* &:hover*/
        .boxHoverThamDu {
            display: block;
            border: 1px solid #cdcdcd;
            z-index: 15;
        }

        .boxHoverThamDu {
            background-color: #ffffff;
            position: absolute;
            width: 350px;
            top: 35px;
            min-height: 270px;
            left: -80px;
            border-radius: 5px;
            display: none;

            &::after {
                position: absolute;
                content: "";
                top: -8px;
                right: 30px;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid #cdcdcd;
                transform: rotate(180deg);
            }
        }

        .title-top {
            white-space: pre-line;
        }
    }
}

.wrap-main .boxThongKe .box-chart::after {
    top: 0px;
    height: 92%;
    right: -21px;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
    max-height: 201px;
    overflow-y: auto;
}

.wrap-main .boxThongKe .mainBoxSlideThongKe {
    position: relative;
    min-height: 107px;
    z-index: 111;
}

.boxThongKe {
    .simple-select {
        .arrow {
            ul {
                li {
                    span {
                        button {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}

.select-date {
    z-index: 999 !important;
}

.dnwebkit-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 22px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dnwebkit-lineDoanhNghiep {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 22px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
// End CSS file Danh sách bài viết
.img-sanpham-bcvp {
    border-radius: 5px;
    max-height: 50px;
}
//Style custom modal danh sách người thích bài viết
.modal-open {
    padding-right: 0px !important;
}

.modal-open .modal {
    overflow: hidden;
}
// style trang kết nối cá nhân
.wrap-main-KetNoi {
    padding: 20px 0 50px;

    .info-thongtin.d-flex {
        padding: 10px 5px;
        border: 1px solid #bcbcbc;
        border-radius: 2px;
        margin: 5px 0;
    }

    .info-thongtin-banbe {
        border: 1px solid #bcbcbc;
        background-color: white;
        margin: 5px 0;
        border-radius: 2px;

        .ava-user .user-image {
            width: 100px;
            height: 100px;
            border-right: 1px solid #cdcdcd;
        }

        .info {
            margin: auto;
            justify-content: center;
            align-items: center;
        }

        .info-user {
            min-height: 45px;
        }

        .dropdown-action-custom button::after {
            display: none !important;
        }
    }

    .boxLoiMoi {
        .info-thongtin {
            border: 1px solid #cdcdcd;
            border-radius: 2px;
            background-color: #ffffff;
            position: relative;

            img {
                height: 100%;
                align-items: center;
                text-align: center;
                margin: auto;
                width: 100%;
                border-bottom: 1px solid #cdcdcd;
            }

            .btn-action {
                padding: 0px 8px;
                border: 1px solid #ccd0d5;
                background-color: #f5f6f7;
                color: #4b4f56 !important;
                font-weight: bold;
                border-radius: 2px;
            }

            .btn-action:focus {
                outline: none;
            }
        }
    }

    .boxDangKetNoi {
        background-color: #e9ebee;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 1rem;
    }

    .boxLoiMoi,
    .boxGoiY {
        background-color: #e9ebee;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 1rem;

        .user-image {
            width: 35px;
            height: 35px;
            border: 1px solid #cdcdcd;
        }

        .info-thongtin {
            background-color: white;
        }
    }
}

.wrap-main-TheoDoi {
    padding: 20px 0 50px;

    .info-thongtin.d-flex {
        padding: 10px 5px;
        border: 1px solid #bcbcbc;
        border-radius: 2px;
        margin: 5px 0;
    }

    .info-thongtin-banbe {
        border-radius: 2px;
        margin: 5px 0;

        .ava-user .user-image {
            width: 100px;
            height: 100px;
            border: 1px solid #cdcdcd;
        }

        .info {
            margin: auto;
            justify-content: center;
            align-items: center;
        }

        .info-user {
            min-height: 45px;
        }
    }

    .boxLoiMoi {
        .info-thongtin {
            border: 1px solid #cdcdcd;
            border-radius: 2px;
            background-color: #ffffff;
            position: relative;

            img {
                height: 100%;
                align-items: center;
                text-align: center;
                margin: auto;
                width: 100%;
                border-bottom: 1px solid #cdcdcd;
            }

            .btn-action {
                padding: 0px 8px;
                border: 1px solid #ccd0d5;
                background-color: #f5f6f7;
                color: #4b4f56 !important;
                font-weight: bold;
                border-radius: 2px;
            }

            .btn-action:focus {
                outline: none;
            }
        }
    }

    .boxDangKetNoi,
    .boxLoiMoi,
    .boxGoiY {
        background-color: #e9ebee;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 1rem;

        .user-image {
            width: 35px;
            height: 35px;
            border: 1px solid #cdcdcd;
        }

        .info-thongtin {
            background-color: white;
        }
    }
}

.boxGoiY .ava-user {
}
// End Style custom modal danh sách người thích bài viết
/// trang cá nhân
.wrap-main-trang-ca-nhan {
    padding: 20px 0 40px;

    .main-tab-content {
        .list-group {
            border-bottom: 1px solid #bcbcbc82;

            .list-group-item-action {
                color: #044587;
                width: auto;
                border: 0px !important;

                &.active {
                    background-color: transparent !important;
                    border: 1px solid #bcbcbc !important;
                    border-bottom-color: #fff !important;
                    border-radius: 5px;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .font-10 {
        font-size: 10px !important;
    }

    .box-detail-user {
        .box-detail-user_child {
            padding: 20px 0 10px;

            .col-left {
                width: 30%;
                min-height: 50px;
                margin-right: 40px;

                .avatar {
                    margin-bottom: 20px;
                }

                .detail-user {
                    input {
                        border: 0px;
                        background-color: #fff;

                        &::placeholder {
                            color: #333333;
                            font-weight: bold;
                        }
                    }
                }
            }

            .col-right {
                width: 70%;
                margin-left: 15px;

                .box-top {
                    height: 40%;
                }

                .box-main-bottom {
                    position: relative;
                    /*&:hover .btn-pencil {
                        visibility: visible;
                        transition: all ease-in-out 1s;
                    }*/
                    .box-bottom {
                        height: 50%;
                        width: 100%;

                        .inp_name_user {
                            border: 0px;
                            background-color: #fff;

                            &::placeholder {
                                color: #333333;
                                font-weight: bold;
                                font-size: 16px;
                            }
                        }

                        .slc_sex {
                            border: 0;
                            background-color: #fff;
                            padding-left: 0;
                            color: #333333;
                        }

                        .inp_age {
                            border: 0px;
                            background-color: #fff;

                            &::placeholder {
                                color: #333333;
                                font-size: 16px;
                            }
                        }
                    }

                    .btn-pencil {
                        visibility: visible;
                        transition: all ease-in-out 1s;
                    }
                }
            }
        }
    }

    .linh-vuc-quan-tam {
        margin-top: 30px;
        padding: 30px 0;
        border-top: 1px solid #bcbcbc82;
        border-bottom: 1px solid #bcbcbc82;

        .box_slc {
            .item-tag {
                min-width: calc(100% / 7 - 10px);
                margin-right: 10px;
                border: 1px solid;
                border-radius: 20px;
                margin-top: 10px;
                text-align: center;
                padding: 5px;
                font-size: 12px;
                color: #fff;
                cursor: pointer;
                background: #044587;
                position: relative;

                &:hover .btn-close {
                    display: block;
                }

                .btn-close {
                    position: absolute;
                    top: -11px;
                    right: 0px;
                    border-radius: 50%;
                    border: 1px solid #bcbcbc;
                    width: 20px;
                    height: 20px;
                    display: none;
                }
            }
        }
    }

    .box-thong-tin-ca-nhan {
        padding: 30px 0;

        .box-thong-tin-ca-nhan_detail {
            .col-left,
            .col-right {
                width: calc(50% - 20px);
                min-height: 200px;

                .item {
                    margin-top: 9px;
                }
            }

            .col-left {
            }
        }
    }

    .box-thong-tin-ket-noi {
        padding: 30px 0;
        border-top: 1px solid #bcbcbc82;
        border-bottom: 1px solid #bcbcbc82;

        .box-icon_plus {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #044587;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 60%;
                height: 1px;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                height: 60%;
                width: 1px;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #fff;
            }
        }

        .content {
            .col-left,
            .col-right {
                width: calc(50% - 60px);
            }

            .ava-small {
                flex-direction: row-reverse;

                .ava-user {
                    overflow: hidden;
                    width: 45px;
                    height: 45px;
                    border: 2px solid #fff;
                    background-color: #044587;
                    border-radius: 50%;
                    z-index: 10;
                    margin-left: -10px;

                    &.child {
                    }
                }
            }

            .so-nguoi-theo-doi {
                width: 35px;
                height: 35px;
                position: relative;
                background-color: #a8071382;
                border-radius: 50%;
                overflow: hidden;
            }
        }
    }

    .box-danh-sach-bai-dang {
        .content {
        }
    }

    &.wrap-main-bo-sung-tai-khoan-cho-lan-dang-nhap-dau-tien {
        .linh-vuc-quan-tam {
            margin: 0;
            border-top: 0px;
        }

        .box-co-the-ban-biet,
        .box-co-the-ban-quan-tam {
            padding: 30px 0;
            width: 100%;
            margin: auto;
            position: relative;

            .arrow-left {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -40px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .arrow-right {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: -30px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .slide-box-co-the-ban-biet,
            .slide-box-co-the-quan-tam {
                .col-right {
                    margin-left: 10px;

                    .info-user {
                        height: 100%;
                    }
                }

                margin: 0px !important;

                .btn-75 {
                    width: 75px !important;
                }

                .slick-list {
                    .slick-track {
                        margin-left: initial !important;
                        margin-right: initial !important;

                        .slick-slide {
                            padding: 0;
                        }
                    }
                }

                .item {
                    &:focus {
                        outline: none;
                    }

                    padding-right: 10px;
                    // min-height: 200px;
                    .item-follow {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

.wrap-main-trang-ca-nhan .box-bottom {
    border-bottom: none;
}

.wrap-main-trang-ca-nhan .box-bottom.user-trang-ca-nhan {
    height: 100% !important;
}

.chat-box-col-right {
    .box-input-chat {
        form {
            width: 100%;
            display: flex;
        }
    }
}

.item-menu-navbar {
    &.active {
        background-color: #f8f9fa;
        margin-bottom: 2px;
    }

    &:hover {
        background-color: #f8f9fa;
    }

    .item-menu__col-right {
        position: relative;

        &:hover .col-right__drop-down {
            display: flex;
        }

        .col-right__drop-down {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 0;
            width: 35px;
            height: 35px;
            border: 1px solid #ccc;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            box-shadow: 0px 0px 0px 1px #0000001a;
            display: none;
        }
    }
}

.list-menu-cha {
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 3px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #bcbcbc82;
    }
}

.box-comment_bottom {
    border: 1px solid #828282bc;
    border-radius: 5px;
    padding: 7px;
    min-height: 80px;

    .box-comment {
        textarea {
            border: 0px;
            padding: 15px;

            &:focus {
                border: none;
                overflow: auto;
                outline: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                resize: none;
            }
        }
    }
}
//style hỏi đáp
.list-attachment-hoidap .div-item-img {
    border-radius: 5px;
}

.form-select.form-control .dropdown .dropdown-trigger .tag-list .tag-item .placeholder {
    font-family: "Roboto-Light";
    font-size: 14px;
}
//slide home
.slide .sl-between span {
    transition: all 0.5s ease;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
}

.sl-img {
    max-width: 155px;
    border-radius: 10px;
    height: 155px;
    border: 1px solid #ccc;
    margin: auto;
}

.slide .sl-left img.sl-img {
    transition: all 0.5s ease;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
    width: 100%;
}

.slide .sl-right img.sl-img {
    transition: all 0.5s ease;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
    width: 100%;
}

.slide .sl-between a {
    transition: all 0.3s ease;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
}

.slide.animateIn.previous .sl-between span,
.slide.current .sl-between span,
.slide.animateIn.next .sl-between span,
.slide.animateIn.previous .sl-between a,
.slide.current .sl-between a,
.slide.animateIn.next .sl-between a,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.previous .sl-left img.sl-img,
.slide.current .sl-left img.sl-img,
.slide.animateIn.next .sl-left img.sl-img,
.slide.animateIn.previous .sl-right img.sl-img,
.slide.current .sl-right img.sl-img,
.slide.animateIn.next .sl-right img.sl-img,
.slide.animateIn.next button {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 1.2s;
    transition-delay: 1.2s;
    opacity: 1;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s;
}

.slide.animateOut .sl-between span {
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
}

.slide.animateOut img.sl-img {
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
}

.slide.animateOut .sl-between a {
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
}

.slider-wrapper {
    position: relative;
    height: 32vh;
    overflow: hidden;
    margin: 0 auto;
}

.slide {
    height: 30vh;
    background-size: cover !important;
}

.slide::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
}

.previousButton,
.nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: url("../../assets/images/btn-next.png") no-repeat center center / 16px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
    background: url("../../assets/images/btn-next.png") no-repeat center center / 16px;
}

.slider-wrapper {
    /*background: url("../../assets/images/bg-slider.png") no-repeat center center;
        background-size: cover;*/
}

.previousButton {
    left: 185px;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
    transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
    left: 175px;
}

.nextButton {
    right: 185px;
}

.nextButton:hover {
    right: 175px;
}

.slider-content {
    text-align: center;
}

.slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
/*.slider-content * {
    pointer-events: none;
}*/
.slider-content .inner button {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #ffffff;
    font-size: 48px;
    line-height: 1;
}

.slider-content .inner p {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

.slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.slider-content section span {
    color: #ffffff;
}

.slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
}

.slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
}

.sl-left {
    margin-bottom: 25px;
}

.sl-title {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: 'Roboto-Bold';
}

.sl-daidien {
    font-size: 14px;
    font-family: 'Roboto-Light';
}

.sl-overview {
    font-size: 12px;
    margin-top: 10px;
    font-family: 'Roboto-Regular';
    color: #A80713;
}

.sl-tt-daidien {
    font-size: 14px;
    font-family: Roboto-Bold
}

.slider-content section span strong {
    color: #ffffff;
    font-size: 14px;
    display: block;
}

.sl-between {
    margin: auto;
    text-align: left;
}
.thuonghieu-title {
    color: #044587;
}
@media (max-height: 750px) {
    .slider-wrapper {
        height: 80vh;
    }
    .thuonghieu-title{
        color:#fff;
    }
}

@media (max-height: 500px) {
    .slider-wrapper,
    .slide {
        height: calc(50vh - 75px);
    }
}

@media (max-width: 640px) {
    .slider-wrapper,
    .slide {
        height: calc(50vh - 75px);
    }

    .nextButton {
        right: 0px;
    }

    .nextButton:hover {
        right: -5px;
    }

    .previousButton {
        left: 0px;
    }

    .previousButton:hover {
        left: -5px;
    }

    .wrap-main .boxThongKe .mainBoxSlideThongKe .boxSlideThongKe .slick-arrow.slick-next {
        right: -10px;
    }

    .wrap-main .boxThongKe .mainBoxSlideThongKe .boxSlideThongKe .slick-arrow.slick-prev {
        left: -10px;
    }

    .sl-img {
        height: auto;
    }

    .sl-left {
        margin-top: 30px;
    }
}

@media (max-height: 600px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

.title-box {
    text-align: center;
    color: #044587;
    font-size: 30px !important;
    font-family: 'Roboto-Light';
}

.box-bottom {
    border-bottom: 3px solid #044587;
    width: 8%;
    margin: auto;
    margin-bottom: 20px;
}

.thuong-hieu {
    .box-list-san-pham {
        padding: 10px 0;
        /*.pagination-page {
            border-top: 1px solid #bcbcbc82;
            border-bottom: 1px solid #bcbcbc82;
        }*/
        justify-content: space-between;

        .item-sanPhamborder {
            //width: calc(100% / 2 - 10px);
            min-height: 30px;
            padding-bottom: 30px;
            //padding-top: 30px;
            //border-top: 1px solid #bcbcbc92;
            .col-top {
                .img-col-left {
                    border: 2px solid #bcbcbc82;
                    border-radius: 15px;
                }
            }

            .col-bottom {
                .content-item {
                    p {
                        overflow: hidden;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        // line-height: 22px;
                        /* max-height: 32px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .border-image {
            border: 1px solid #bcbcbc92;
            border-radius: 5px 5px 5px 5px;
            max-height: 200px;

            .position-img {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    a.active-image {
        position: relative;
    }

    a.active-image::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: #828282bc;
        opacity: 0.3;
        top: 0;
        left: 0;
    }

    .boxSlideThuongHieu {
        .owl-nav {
            top: -60px;
            margin-top: 25px !important;
            margin-right: 10px !important;
        }
    }
}

.tabthuonghieu.active {
    background-color: #044587 !important;
}

.line-bottom {
    border-bottom: 2px solid #044587;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}

.search-sp {
    background: none !important;
    border: none;
    padding: 0 !important;
}

.owl-item.ytp-chrome-top {
    display: none !important;
}

.owl-next span, .owl-prev span {
    font-size: 40px;
    color: #b3b3b3;
}

.boxNhanDienThuongHieu {
    background: url("../../assets/images/bg-gioithieu.png") no-repeat;
/*    background-size: cover;*/
background-position:top;
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 0 !important;
}

.boxSlideboxPhanAnhViPham .wrapper-list-phan-anh {
    min-height: 108px;
}

.lsttab-thuonghieu .tabthuonghieu {
    padding: 7px;
}

.footer-notification {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    color: #ffffff;
    background-color: #ff1100;
    font-weight: 600;
    max-height: 25px;
}

.css-lst-hoithoai {
    height: 75vh;
    overflow: auto;
}

.box-gioithieu {
    min-height: 410px;
}
.description-rgt {
    margin-top: 5%;
    font-family: 'Roboto-Regular';
}
.description-gt {
    font-size: 14px;
    font-family: 'Roboto-Light';
    margin-top: 5%;

    .btn-xemthem {
        background-color: #11396b;
        color: #fff;
        width: 130px;
        margin-top:20px;
    }
}
